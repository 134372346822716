import React from "react";
import { FilledInputProps, InputAdornment, TextField } from "@mui/material";
import { CommonInputProps } from "common/types/types";
import { IWithErrorMessage } from "wes_shell_app/types";
import { getTranslation } from "wes_shell_app/localization-utils";

type InputPropsBase = IWithErrorMessage & {
  onConfirm?: (v) => void;
  type?: React.HTMLInputTypeAttribute;
  endAdormnent?: React.ReactNode;
  startAdornment?: React.ReactNode;
  select?: boolean;
  children?: React.ReactNode;
  size?: "small";
  useDebounce?: boolean;
  onKeyDown?: (e) => void;
  error?: boolean;
  required?: boolean;
};

export type IInputFieldProps<T> = InputPropsBase &
  CommonInputProps<T, React.ChangeEvent<HTMLInputElement>> & {
    InputProps?: Partial<FilledInputProps>;
  };

export const InputField = <T,>(props: IInputFieldProps<T>) => {
  const {
    label,
    readOnly,
    value,
    placeholder,
    onChange,
    onConfirm,
    type,
    select,
    endAdormnent,
    startAdornment,
    errorMessage,
    children,
    error,
    required,
  } = props;

  function onValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value as unknown as T, event);
  }

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) =>
    onConfirm?.(event);

  return (
    <TextField
      required={required}
      fullWidth
      type={type}
      onKeyDown={props.onKeyDown}
      placeholder={getTranslation(placeholder)}
      label={getTranslation(label)}
      disabled={readOnly}
      value={value ?? ""}
      size={props.size}
      error={
        typeof errorMessage === "string"
          ? !!errorMessage?.length
          : React.isValidElement(errorMessage)
      }
      helperText={errorMessage}
      onChange={onValueChange}
      onBlur={onBlur}
      select={select}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end">{endAdormnent}</InputAdornment>
        ),
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        className: "rounded-full",
      }}
    >
      {children}
    </TextField>
  );
};
