import { Dialog } from "common/base-components/dialog/dialog";
import { SortPlansViewStore } from "features/sort-plans/stores/sort-plans-view-store";
import Text from "wes_shell_app/text";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

type SortPlansCheckSortingOutputsDialogProps = {
  open: boolean;
  onClose: () => void;
  store: SortPlansViewStore;
  handleCheckSortingOutputsDialog: () => void;
};

const SortPlansCheckSortingOutputsDialog = (
  props: SortPlansCheckSortingOutputsDialogProps
) => {
  return (
    <Dialog
      open={props.open}
      intlTitle="sortingOutputCheck"
      cancelAction={props.onClose}
      onClose={props.onClose}
      confirmAction={() => props.handleCheckSortingOutputsDialog()}
      confirmLabel="yes"
      cancelLabel="no"
    >
      <div className="flex flex-col gap-2 items-center">
        <ErrorOutlineOutlinedIcon
          sx={{ fontSize: 130, color: (theme) => theme.palette.grey[300] }}
        />
        <Text
          intlId="sortingOutputCheckContent"
          intlParams={{
            numberOfSortingOutputs:
              props.store.checkSortingOutputs[0]?.unassignedContainers,
          }}
        />
      </div>
    </Dialog>
  );
};

export default SortPlansCheckSortingOutputsDialog;
