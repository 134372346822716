import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { useSortPlansStore } from "../hooks/use-sort-plans-store";
import { IClientSorterPlanModel } from "models/client-models";
import { SortPlansViewStore } from "../stores/sort-plans-view-store";
import { TableCell, TableRow } from "@mui/material";
import Button from "wes_shell_app/button";
import { List, PlayArrow } from "@mui/icons-material";
import SortPlansWaveStartDialog from "./sort-plans-wave-start-dialog";
import SortPlansTableRowSelector from "./sort-plans-table-row-selector";
import { SelectItemType } from "wes_shell_app/types";
import { SortPlansRequestDataProvider } from "../stores/data/sort-plans-request-data-provider";
import alerts from "wes_shell_app/alerts";
import {
  IAPIUpdateSortPlanIDModel,
  IAPICheckWaveRunningModel,
} from "models/server-models";
import { useDetailSortPlansStore } from "../../sort-plan-detail/hooks/use-detail-sort-plan-store";
import { WaveRunningStore } from "stores/common/wave-running-store";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
    sortKey: "id",
  },
  {
    label: "status",
    sortKey: "state",
  },
  {
    label: "name",
    sortKey: "name",
  },
  {
    label: "created",
    sortKey: "created",
  },
  {
    label: "updated",
    sortKey: "updated",
  },
];

export const SortPlansDataTable = observer(() => {
  const store = useSortPlansStore();
  const waveRunningStore = new WaveRunningStore();
  const detailStore = useDetailSortPlansStore();
  const provider = new SortPlansRequestDataProvider(store);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [showStartButton, setShowStartButton] = useState<boolean>(false);

  useEffect(() => {
    const checkWaveRunning = async () => {
      await waveRunningStore.getWaveRunning();
      waveRunningStore.waveRunning?.id || setShowStartButton(true);
    };

    checkWaveRunning();
  }, []);

  if (!store.isLoaded) return null;

  const handleDrawer = async (item: IClientSorterPlanModel) => {
    detailStore.setSelectSortPlan = item;
    try {
      await detailStore.setPhysLocation(provider.defaultModel.stationId);
      detailStore.forceReload();
      store.openDrawer();
    } catch (e) {}
  };

  const handleRowSelect = (id: number, name: string) => {
    if (store.sortplanTableRowSelected === id) {
      store.resetSortPlanTableRowSelected();
      store.setSortPlanCloneButton(false);
      store.resetSelectedCloneSortPlan(true);
    } else {
      store.setSortPlanTableRowSelected(id);
      store.setSortPlanCloneButton(true);
      store.setSelectedCloneSortPlan(id, name);
    }
  };

  const handleChangeState = async (state: string, id: number) => {
    try {
      (await provider.updateSortPlanState(
        state,
        id
      )) as IAPIUpdateSortPlanIDModel;
      store.forceReload();
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  };

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => (
        <RowTemplate
          key={item.id}
          item={item}
          store={store}
          isAdmin={store.isAdmin}
          onSelect={() => handleRowSelect(item.id, item.name)}
          changeState={handleChangeState}
          handleDrawer={handleDrawer}
          showStartButton={showStartButton}
        />
      )}
    />
  );
});

const items: SelectItemType[] = [
  {
    contentIntl: "statusActive",
    id: "active",
  },
  {
    contentIntl: "statusDeleted",
    id: "deleted",
  },
];

type IRowTemplateProps = {
  item: IClientSorterPlanModel;
  store: SortPlansViewStore;
  isAdmin?: boolean;
  onSelect: () => void;
  changeState: (state: string, id: number) => void;
  handleDrawer: (id: IClientSorterPlanModel) => void;
  showStartButton: boolean;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { item, store, onSelect, changeState, handleDrawer, showStartButton } =
    props;
  const { id, state, name, created, updated } = item;

  const handleFormControlClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <TableRow
      onClick={onSelect}
      selected={store.sortplanTableRowSelected === id}
      sx={{
        cursor: "pointer",
        backgroundColor:
          store.sortplanTableRowSelected === id
            ? "rgb(37 58 127 / 13%) !important"
            : "",
      }}
    >
      <TableCell>{id}</TableCell>
      <TableCell>
        <div onClick={handleFormControlClick} className="w-3/4">
          <SortPlansTableRowSelector
            value={state}
            onChange={(v: string) => changeState(v, id)}
            items={items}
            formSizeSmall={true}
          />
        </div>
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{created}</TableCell>
      <TableCell>{updated}</TableCell>
      <TableCell>
        {showStartButton && state === "active" ? (
          <SortPlansWaveStartDialog
            icon={<PlayArrow color="success" />}
            item={item}
          />
        ) : (
          <Button
            variant="icon"
            icon={<PlayArrow sx={{ color: "#00000000" }} />}
            disabled={true}
            onClick={() => {}}
          />
        )}
      </TableCell>
      <TableCell>
        <Button
          variant="icon"
          icon={<List color="primary" />}
          onClick={(event) => {
            event.stopPropagation();
            handleDrawer(item);
          }}
        />
      </TableCell>
    </TableRow>
  );
});
