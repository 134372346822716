import { observer } from "mobx-react";
import React from "react";
import { SimpleCard } from "wes_shell_app/simpleCard";
import KpiCharts from "common/base-components/dialog/kpi/kpi-chart";
import { useSorterDashboardStore } from "../hooks/use-sorter-dashboard-store";
import SimpleBarChart from "wes_shell_app/bar-chart";
import SimplePieChart from "wes_shell_app/pie-chart";
import { getTranslation } from "wes_shell_app/localization-utils";

export const DashboardHeader = observer(() => {
  const store = useSorterDashboardStore();
  React.useEffect(() => {
    store.openConnection();

    return () => {
      store.closeConnection();
    };
  }, [store]);

  if (!store.isLoaded) return null;

  return (
    <React.Fragment>
      <div className="flex w-full gap-2 flex-wrap">
        <SimpleCard topMargin={false} className="flex-1">
          <KpiCharts
            chart={
              <SimplePieChart
                currentCount={store.ordersFullnessArray[0]}
                maxCount={store.ordersFullnessArray[1]}
                labels={[getTranslation("sorted"), getTranslation("remaining")]}
                colors={["000", "rgb(219 219 219)"]}
                tooltip={!store.ordersFullnessArray[1] ? false : true}
              />
            }
            intlId="orders"
            value={store.ordersFullnessArray}
            description={[getTranslation("sorted"), getTranslation("total")]}
          ></KpiCharts>
        </SimpleCard>
        <SimpleCard topMargin={false} className="flex-1">
          <KpiCharts
            chart={
              <SimplePieChart
                currentCount={store.itemsFullnessArray[0]}
                maxCount={store.itemsFullnessArray[1]}
                labels={[getTranslation("sorted"), getTranslation("remaining")]}
                colors={["000", "rgb(219 219 219)"]}
                tooltip={!store.itemsFullnessArray[1] ? false : true}
              />
            }
            intlId="items"
            value={store.itemsFullnessArray}
            description={[getTranslation("sorted"), getTranslation("total")]}
          ></KpiCharts>
        </SimpleCard>
        <SimpleCard topMargin={false} className="flex-1">
          <KpiCharts
            chart={
              <SimpleBarChart
                data={[10, 20, 30, 5, 15, 25, 35, 5, 40, 8]}
                labels={[
                  "Group A",
                  "Group B",
                  "Group C",
                  "Group D",
                  "Group E",
                  "Group F",
                  "Group G",
                  "Group H",
                  "Group I",
                  "Group J",
                ]} /* TODO MIJN test labels */
                color={["000"]}
              />
            }
            intlId="itemsInHospital"
            value={store.hospitalFullnessArray}
            description={[getTranslation("total")]}
          ></KpiCharts>
        </SimpleCard>
        <SimpleCard topMargin={false} className="flex-1">
          <KpiCharts
            chart={
              <SimpleBarChart
                data={[10, 20, 30, 5, 15, 25, 35, 5, 40, 8]}
                labels={[
                  "Group A",
                  "Group B",
                  "Group C",
                  "Group D",
                  "Group E",
                  "Group F",
                  "Group G",
                  "Group H",
                  "Group I",
                  "Group J",
                ]} /* TODO MIJN test labels */
                color={["000"]}
              />
            }
            intlId="itemsInBuffer"
            value={store.bufferFullnessArray}
            description={[getTranslation("total")]}
          ></KpiCharts>
        </SimpleCard>
      </div>
    </React.Fragment>
  );
});
