import React from "react";
import { Routes, Route } from "react-router-dom";
import routerStore from "wes_shell_app/router-store";
import DashboardView from "features/dashboard/views/dashboard-view";
import InductionStationSelectView from "features/induction-station/views/induction-station-select-view";
import WavesView from "features/waves/views/waves-view";
import WaveDetailView from "features/wave-detail/view/wave-detail-view";
import { useNavigationStore } from "wes_shell_app/use-navigation-store";
import { observer } from "mobx-react";
import { useInductionStationViewStore } from "features/induction-station/hooks/use-induction-station-view-store";
import { sorterMenu, SorterRoutePaths } from "./sorter-menu";
import InductionStationViewWrapper from "features/induction-station/views/wrappers/induction-station-view-wrapper";
import SortPlansView from "features/sort-plans/views/sort-plans-view";
import { useSettingsStore } from "wes_shell_app/use-setting-store";
import SettingView from "features/settings/views/setting-view";
import { useSorterSettingViewStore } from "features/settings/hooks/use-sorter-setting-store";
import WaveCorrectionsView from "features/wave-corrections/views/wave-corrections-view";
import SharedContextStore from "wes_shell_app/shared-context-store";
import PlatformView from "features/platform/views/platform-view";
import ChartsView from "features/charts/views/charts-view";
import { useHandBarcodeScanner } from "features/induction-station/hooks/use-hand-barcode-scanner";

export const SorterRoutes = observer(() => {
  const inductionStore = useInductionStationViewStore();
  const navStore = useNavigationStore();
  const settingsStore = useSettingsStore();
  const sorterSettingViewStore = useSorterSettingViewStore();
  const sharedContextStore = new SharedContextStore();

  useHandBarcodeScanner();

  const inductionStations = inductionStore.actualInductionStations
    .flatMap((station) => station.inductionStations)
    .map((station) => station);

  React.useEffect(() => {
    routerStore.addPaths([...Object.values(SorterRoutePaths)]);
    inductionStore.setInductionStations();
    sorterSettingViewStore.getSettingModules();
    sorterSettingViewStore.getSettingComponents();
  }, []);

  React.useEffect(() => {
    navStore.setItems(sorterMenu(inductionStations).groups);
  }, [inductionStations]);

  React.useEffect(() => {
    settingsStore.setActiveComponent(SettingView(sorterSettingViewStore));

    return () => {
      settingsStore.resetActiveComponent();
    };
  }, [sorterSettingViewStore.modules, sorterSettingViewStore.components]);

  return (
    <Routes>
      <Route
        path={SorterRoutePaths.home}
        element={
          sharedContextStore.appContext.accessRights.isAdminRole ||
          sharedContextStore.appContext.accessRights.isLeaderRole ? (
            <DashboardView />
          ) : (
            <InductionStationSelectView />
          )
        }
      />
      <Route path={SorterRoutePaths.charts} element={<ChartsView />} />
      <Route
        path={SorterRoutePaths.inductionStation + "/:id"}
        element={<InductionStationViewWrapper />}
      />
      <Route path={SorterRoutePaths.waves} element={<WavesView />} />
      <Route
        path={SorterRoutePaths.waves + "/:id"}
        element={<WaveDetailView />}
      />
      <Route path={SorterRoutePaths.sortPlan} element={<SortPlansView />} />
      <Route
        path={SorterRoutePaths.corrections}
        element={<WaveCorrectionsView />}
      />
      <Route path={SorterRoutePaths.platform} element={<PlatformView />} />
    </Routes>
  );
});
