import Text from "wes_shell_app/big";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const DialogRestartModuleMessage = () => {

  return (
    <div className="flex flex-col gap-2 items-center">
      <ErrorOutlineOutlinedIcon sx={{ fontSize: 130, color: (theme) => theme.palette.grey[300] }}/>
      <Text intlId="modulsRestartText" sx={{ fontWeight: 'bold' }}/>
    </div>
  );
};

export default DialogRestartModuleMessage;
