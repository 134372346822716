import { SortPlansViewStore } from "features/sort-plans/stores/sort-plans-view-store";
import { observer } from "mobx-react";
import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";

type WaveSelectorProps = {
  required: boolean;
  store: SortPlansViewStore;
};

const WaveSelector = observer((props: WaveSelectorProps) => {
  const { required, store } = props;

  const Items: SelectItemType[] = store.filterRunWave?.content.map((item) => ({
    id: item.id,
    content: item.name,
  }));

  return (
    <>
      <Select
        intlId={"waveSelect"}
        required={required}
        value={store.sortPlanSelectedWave}
        onChange={(v: number) => {
          store.setSortPlanSelectedWave(v);
        }}
        items={Items}
        fullWidth
      />
    </>
  );
});

export default WaveSelector;
