import BaseView from "wes_shell_app/base-view";
import WaveCorrectionDataTable from "../components/wave-corrections-data-table";
import WaveCorrectionsDataTableFilter from "../components/wave-corrections-data-table-filter";
import { useEffect } from "react";
import { useWaveCorrectionStore } from "../hooks/use-wave-corrections-view-store";
import { useWaveCorrectionWaveRunningStore } from "../hooks/use-wave-corrections-wave-running-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

const WaveCorrectionsView = () => {
  const store = useWaveCorrectionStore();
  const waveRunnigStore = useWaveCorrectionWaveRunningStore();
  const sharedContextStore = new SharedContextStore();
  const UserAccess =
    sharedContextStore.appContext.accessRights.getUserAccessEnums;

  useEffect(() => {
    const fetchAndSetWaveRunning = async () => {
      await waveRunnigStore.getWaveRunning();
      const waveRunningId = waveRunnigStore.waveRunning?.id ?? 0;
      store.providerforceReload(waveRunningId);
    };

    fetchAndSetWaveRunning();
  }, [store, waveRunnigStore]);

  return (
    <BaseView accessKey={UserAccess.SORTER_NAV_ITEM}>
      <WaveCorrectionsDataTableFilter />
      <WaveCorrectionDataTable />
    </BaseView>
  );
};

export default WaveCorrectionsView;
