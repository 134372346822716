import { Close } from "@mui/icons-material";
import Button from "wes_shell_app/button";
import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";

const items: SelectItemType[] = [
  {
    contentIntl: "singleType",
    id: "single",
  },
  {
    contentIntl: "infiniteType",
    id: "infinite",
  },
];

type IActiveFilterSelectorProps = {
  value: string;
  onChange: (v: string) => void;
  onClear: () => void;
};

export const TypeSelector = ({
  value,
  onChange,
  onClear,
}: IActiveFilterSelectorProps) => {
  const onChangeHandler = (v: string) => onChange(v);

  return (
    <Select
      fullWidth
      intlId="typeSelector"
      placeholder="typeSelector"
      items={items}
      value={value ? `${value}` : undefined}
      onChange={onChangeHandler}
      endAdornment={
        value ? (
          <div className="mr-4 flex align-center">
            <Button variant="small-icon" onClick={onClear}>
              <Close fontSize="small" color="action" />
            </Button>
          </div>
        ) : undefined
      }
    />
  );
};
