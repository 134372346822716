import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import Text from "wes_shell_app/text";
import { useSorterWavesStore } from "../../hooks/use-waves-store";
import { Stack } from "@mui/material";
import { Cancel } from "@mui/icons-material";

type waveStartDialogProps = {
  isOpened: boolean;
  onClose: () => void;
};

export const RunWaveFailureDialog = observer((props: waveStartDialogProps) => {
  const store = useSorterWavesStore();
  return (
    <Dialog
      intlTitle="waveRunFailureTitle"
      open={props.isOpened}
      onClose={props.onClose}
    >
      <Stack alignItems="center">
        <Cancel
          fontSize="large"
          color="error"
          sx={{ transform: "scale(4)", margin: "4rem" }}
        />
        <Text
          intlId="waveRunFailureContent"
          intlParams={{ sortPlan: store.runWaveStore.selectedSortPlanId }}
        />
      </Stack>
    </Dialog>
  );
});
