import { Dialog } from "common/base-components/dialog/dialog";
import { SortPlansViewStore } from "features/sort-plans/stores/sort-plans-view-store";
import Text from "wes_shell_app/text";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import SortPlansReplacedContainers from "./content/sort-plans-replaced-containers";

type SortPlansReplacedContainersDialogProps = {
  open: boolean;
  onClose: () => void;
  handleReplacedContainersDialog: () => void;
};

const SortPlansReplacedContainersDialog = (
  props: SortPlansReplacedContainersDialogProps
) => {
  const { open, onClose, handleReplacedContainersDialog } = props;
  return (
    <Dialog
      open={open}
      intlTitle="unchangedContainersTitle"
      cancelAction={onClose}
      onClose={onClose}
      confirmAction={() => handleReplacedContainersDialog()}
      confirmLabel="confirm"
    >
      <div className="flex flex-col gap-2 items-center">
        <ErrorOutlineOutlinedIcon
          sx={{ fontSize: 130, color: (theme) => theme.palette.grey[300] }}
        />
        <Text intlId="unchangedContainersDesc" />
        <SortPlansReplacedContainers />
      </div>
    </Dialog>
  );
};

export default SortPlansReplacedContainersDialog;
