import { CheckCircleOutline } from "@mui/icons-material";
import { Dialog } from "common/base-components/dialog/dialog";
import { SortPlansViewStore } from "features/sort-plans/stores/sort-plans-view-store";
import { observer } from "mobx-react";
import Text from "wes_shell_app/text";
import Small from "wes_shell_app/small";

type waveStartDialogProps = {
  open: boolean;
  onClose: () => void;
  store: SortPlansViewStore;
  waveStartedResult: boolean;
  waveStartedMessage: string;
};

const SortPlansRunWaveResultDialog = observer((props: waveStartDialogProps) => {
  return (
    <Dialog
      intlTitle={props.waveStartedResult && "preparationWave"}
      open={props.open}
      onClose={props.onClose}
      cancelAction={props.onClose}
    >
      <div className="flex flex-col gap-5 items-center">
        <CheckCircleOutline
          fontSize="large"
          color={props.waveStartedResult ? "success" : "error"}
          sx={{ transform: "scale(4)", margin: "4rem" }}
        />
        {props.waveStartedResult ? (
          <div className="text-center">
            <Text
              intlId="preparationWaveStarted"
              intlParams={{
                sortPlan: props.store.waveSummary[0]?.sortPlanName,
              }}
            />
            <Small intlId="watchDashboard" />
          </div>
        ) : (
          <Text intlId={props.waveStartedMessage} />
        )}
      </div>
    </Dialog>
  );
});

export default SortPlansRunWaveResultDialog;
