import { TableCell, TableRow } from "@mui/material";
import { DetailSortPlansRequestDataProvider } from "features/sort-plan-detail/stores/data/detail-sort-plans-request-data-provider";
import { observer } from "mobx-react";
import { IClientDetailSortPlanModel } from "models/client-models";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { SelectItemType } from "wes_shell_app/types";
import { useDetailSortPlansStore } from "../hooks/use-detail-sort-plan-store";
import { DetailSortPlansViewStore } from "../stores/detail-sort-plans-store";
import {
  IAPIPhysLocationContent,
  IAPIUpdateSortPlanDetail,
} from "models/server-models";
import DetailSortPlanTableRowSelector from "./detail-sort-plan-table-row-selector";
import alerts from "wes_shell_app/alerts";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "level",
    sortKey: "level",
  },
  {
    label: "finger",
    sortKey: "finger",
  },
  {
    label: "side",
    sortKey: "side",
  },
  {
    label: "status",
    sortKey: "state",
  },
  {
    label: "destination",
    sortKey: "destination",
  },
  {
    label: "type",
    sortKey: "type",
  },
  {
    label: "location",
    sortKey: "phylocationid",
  },
];

export const DetailSortPlanTable = observer(() => {
  const store = useDetailSortPlansStore();
  const provider = new DetailSortPlansRequestDataProvider(store);

  const handleUpdateDetail = async (
    state: string,
    id: number,
    param: string
  ) => {
    try {
      (await provider.updateDetailSortPlan(
        state,
        id,
        param
      )) as IAPIUpdateSortPlanDetail;
      store.forceReload();
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  };

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => (
        <RowTemplate
          key={item.id}
          item={item}
          store={store}
          isAdmin={store.isAdmin}
          handleUpdateDetail={handleUpdateDetail}
        />
      )}
    />
  );
});

const statusItems: SelectItemType[] = [
  {
    contentIntl: "statusActive",
    id: "active",
  },
  {
    contentIntl: "statusBlocked",
    id: "blocked",
  },
];

type IRowTemplateProps = {
  item: IClientDetailSortPlanModel;
  store: DetailSortPlansViewStore;
  isAdmin?: boolean;
  handleUpdateDetail: (value: string, id: number, param: string) => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { item, store, handleUpdateDetail } = props;
  const { id, level, finger, state, destination, type, side, phylocationid } =
    item;

  const handleFormControlClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const locationItems: SelectItemType[] = store.physLocation.map(
    (item: IAPIPhysLocationContent) => ({
      contentIntl: item.location,
      id: item.id.toString(),
    })
  );

  return (
    <TableRow>
      <TableCell>{level}</TableCell>
      <TableCell>{finger}</TableCell>
      <TableCell>{side}</TableCell>
      <TableCell>
        <div onClick={handleFormControlClick} className="w-3/4">
          <DetailSortPlanTableRowSelector
            intld="status"
            placeholder="status"
            value={state}
            onChange={(v: string) => handleUpdateDetail(v, id, "status")}
            items={statusItems}
            formSizeSmall={true}
          />
        </div>
      </TableCell>
      <TableCell>{destination}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell width="30%">
        <div onClick={handleFormControlClick} className="w-full">
          <DetailSortPlanTableRowSelector
            intld="location"
            placeholder="location"
            value={phylocationid}
            onChange={(v: string) => handleUpdateDetail(v, id, "locationID")}
            items={locationItems}
            formSizeSmall={true}
            readOnly={type === "buffer"}
          />
        </div>
      </TableCell>
    </TableRow>
  );
});
