import { observer } from "mobx-react";
import { IClientSorterPlanModel } from "models/client-models";
import React, { ReactElement, useRef, useState } from "react";
import Button from "wes_shell_app/button";
import { useSortPlansStore } from "../hooks/use-sort-plans-store";
import { SortPlansRequestDataProvider } from "../stores/data/sort-plans-request-data-provider";
import SortPlansStartDialog from "./dialogs/sort-plans-start-dialog";
import SortPlansUnsortedOrdersDialog from "./dialogs/sort-plans-unsorted-orders-dialog";
import SortPlansWaveSelectorVisible from "./dialogs/sort-plans-wave-selector-visible";
import SortPlansPhysLocationDialog from "./dialogs/sort-plans-physLocation-dialog";
import SortPlansCheckSortingOutputsDialog from "./dialogs/sort-plans-check-sorting-outputs-dialog";
import SortPlansReplacedContainersDialog from "./dialogs/sort-plans-replaced-containers-dialog";
import SortPlansRunWaveResultDialog from "./dialogs/sort-plans-run-wave-result-dialog";

type ISortsPlanWaveStartDialogProps = {
  icon: ReactElement;
  item: IClientSorterPlanModel;
};

const SortPlansWaveStartDialog = observer(
  (props: ISortsPlanWaveStartDialogProps) => {
    const { item } = props;
    const [isWaveSelectorVisible, setWaveSelectorVisibility] =
      useState<boolean>(false);
    const [startSortPlanDialog, setStartSortPlanDialog] =
      useState<boolean>(false);
    const [physLocationDialog, setPhysLocationDialog] =
      useState<boolean>(false);
    const [isUnsortedOrdersDialogVisible, setUnsortedOrdersDialogVisibility] =
      useState<boolean>(false);
    const [checkSortingOutputsDialog, setCheckSortingOutputsDialog] =
      useState<boolean>(false);
    const [replacedContainersDialog, setReplacedContainersDialog] =
      useState<boolean>(false);
    const [waveRunningDialogVisible, setWaveRunningDialogVisibility] =
      useState<boolean>(false);

    const [waveStartedResult, setWaveStartedResult] = useState<boolean>(false);
    const [waveStartedMessage, setWaveStartedMessage] = useState<string>("");

    const store = useSortPlansStore();
    const provider = new SortPlansRequestDataProvider(store);

    const handleShowDialog = async () => {
      const success = await store.setStartSortPlan(
        provider.defaultModel.stationId
      );
      if (!success) {
        return;
      }
      if (store.unsortedOrders > 0) {
        setUnsortedOrdersDialogVisibility(true);
        return;
      } else {
        setWaveSelectorVisibility(true);
      }
    };
    const handleUnsortedOrdersDialog = async (addOrders: boolean) => {
      const response: boolean = await store.filterRunWaveAPI();
      setUnsortedOrdersDialogVisibility(false);

      if (response) {
        store.setaddUnsortOrders(addOrders);
        setWaveSelectorVisibility(true);
      }
    };
    const handleSelecetWaveDialog = async () => {
      const response: boolean = await store.stepCheckPhysicalLocation();

      if (!response) return;
      setWaveSelectorVisibility(false);

      if (store.physLocation[0]?.physLocationInWave) {
        setPhysLocationDialog(true);
      } else {
        handlePhysLocationDialog();
      }
    };
    const handlePhysLocationDialog = async () => {
      const response: boolean = await store.stepCheckSortingOutputs();
      setPhysLocationDialog(false);

      if (!response) return;
      if (store.checkSortingOutputs[0]?.unassignedContainers) {
        setCheckSortingOutputsDialog(true);
      } else {
        handleCheckSortingOutputsDialog();
      }
    };
    const handleCheckSortingOutputsDialog = async () => {
      const response: boolean = await store.stepReplacedContainers();
      setCheckSortingOutputsDialog(false);

      if (!response) return;
      if (store.replacedContainers.length > 0) {
        setReplacedContainersDialog(true);
      } else {
        await store.getWaveSummaryAPI();
        setStartSortPlanDialog(true);
      }
    };
    const handleReplacedContainersDialog = async () => {
      setReplacedContainersDialog(false);
      const response: boolean = await store.stepReplacedContainers();

      if (!response) return;
      if (store.replacedContainers.length > 0) {
        setReplacedContainersDialog(true);
      } else {
        await store.getWaveSummaryAPI();
        setStartSortPlanDialog(true);
      }
    };

    type startWaveAPIResponse = {
      result: boolean;
      message: string;
    };

    const handleStartWaveDialog = async () => {
      const response: startWaveAPIResponse = await store.startWaveAPI();
      setStartSortPlanDialog(false);
      setWaveStartedResult(response.result);
      setWaveStartedMessage(response.message);
      setWaveRunningDialogVisibility(true);
    };

    return (
      <>
        <SortPlansRunWaveResultDialog
          open={waveRunningDialogVisible}
          onClose={() => setWaveRunningDialogVisibility(false)}
          store={store}
          waveStartedResult={waveStartedResult}
          waveStartedMessage={waveStartedMessage}
        />
        <SortPlansStartDialog
          open={startSortPlanDialog}
          onClose={() => setStartSortPlanDialog(false)}
          handleSlecetWaveDialog={handleStartWaveDialog}
        />
        <SortPlansReplacedContainersDialog
          open={replacedContainersDialog}
          onClose={() => setReplacedContainersDialog(false)}
          handleReplacedContainersDialog={handleReplacedContainersDialog}
        />
        <SortPlansCheckSortingOutputsDialog
          open={checkSortingOutputsDialog}
          onClose={() => setCheckSortingOutputsDialog(false)}
          handleCheckSortingOutputsDialog={handleCheckSortingOutputsDialog}
          store={store}
        />
        <SortPlansPhysLocationDialog
          open={physLocationDialog}
          onClose={() => setPhysLocationDialog(false)}
          store={store}
          handlePhysLocationDialog={handlePhysLocationDialog}
        />
        <SortPlansWaveSelectorVisible
          open={isWaveSelectorVisible}
          onClose={() => setWaveSelectorVisibility(false)}
          store={store}
          handleSelectWaveDialog={handleSelecetWaveDialog}
        />
        <SortPlansUnsortedOrdersDialog
          open={isUnsortedOrdersDialogVisible}
          onClose={() => setUnsortedOrdersDialogVisibility(false)}
          store={store}
          handleUnsortedOrdersDialog={handleUnsortedOrdersDialog}
        />
        <Button
          variant="icon"
          icon={props.icon}
          onClick={(event) => {
            store.setSelectSortPlan = item;
            event.stopPropagation();
            handleShowDialog();
          }}
        />
      </>
    );
  }
);

export default SortPlansWaveStartDialog;
