import { observer } from "mobx-react";
import React from "react";
import BaseView from "wes_shell_app/base-view";
import { OrdersDataTable } from "../components/orders-data-table";
import { OrdersDataTableFilter } from "../components/orders-data-table-filter";
import { OrderLinesDrawer } from "../components/order-lines-drawer";
import SharedContextStore from "wes_shell_app/shared-context-store";

const WaveDetailView = observer(() => {
  const sharedContextStore = new SharedContextStore();
  const UserAccess =
    sharedContextStore.appContext.accessRights.getUserAccessEnums;

  return (
    <BaseView accessKey={UserAccess.SORTER_NAV_ITEM}>
      <OrdersDataTableFilter />
      <OrdersDataTable />
      <OrderLinesDrawer />
    </BaseView>
  );
});

export default WaveDetailView;
