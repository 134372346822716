import { Dialog } from "common/base-components/dialog/dialog";
import SettingsDialogServicesStopMessage from "./content/settings-dialog-services-stop-message";

interface SettingsServicesStopDialogProps {
  open: boolean;
  onClose: () => void;
}

const SettingsServicesStopDialog: React.FC<SettingsServicesStopDialogProps> = ({
  open,
  onClose,
}) => (
  <Dialog
    open={open}
    intlTitle="componentsServicesTesting"
    cancelAction={onClose}
    onClose={onClose}
  >
    <SettingsDialogServicesStopMessage />
  </Dialog>
);

export default SettingsServicesStopDialog;
