import { observer } from "mobx-react";
import { SimpleCard } from "wes_shell_app/simpleCard";
import Card from "common/base-components/card/card";
import { useInductionStationViewStore } from "../hooks/use-induction-station-view-store";
import Big from "wes_shell_app/big";
import BarcodeScannerComponent from "./barcodeScanner";
import { getTranslation } from "wes_shell_app/localization-utils";

const WaveInfo = observer(() => {
  const store = useInductionStationViewStore();
  const { name, waveid, state: waveState } = store.waveInfo || {};

  return (
    <>
      <Big intlId="runningWaveInfo" />
      <div className="flex gap-2">
        <SimpleCard className="max-w-[25%]">
          <Card
            intlId={"wave"}
            mainText={name}
            subTitleFirst="ID"
            subTextFirst={waveid && waveid.toString()}
            subTextSecond={getTranslation(waveState)}
          />
        </SimpleCard>
        <SimpleCard>
          <Card
            intlId={"message"}
            mainText={store.message}
            mainTextSecond={store.testBarcodes.join(", ")}
          />
        </SimpleCard>
        <SimpleCard className="max-w-[20%]">
          <BarcodeScannerComponent />
        </SimpleCard>
      </div>
    </>
  );
});

export default WaveInfo;
