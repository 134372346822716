import React from "react";
import { Dialog } from "common/base-components/dialog/dialog";
import DialogRestartAppMessage from "./content/settings-dialog-restart-app-message";

interface SettingsRestartAppDialogProps {
  open: boolean;
  onClose: () => void;
}

const SettingsRestartAppDialog: React.FC<SettingsRestartAppDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog
      open={open}
      intlTitle="modulsRestartlogoutTitle"
      cancelAction={onClose}
      onClose={onClose}
      cannotClosed={true}
      disableEscapeKeyDown={true}
      dontShowCancelButton={true}
    >
      <DialogRestartAppMessage />
    </Dialog>
  );
};

export default SettingsRestartAppDialog;
