import { runInAction } from "mobx";
import { SorterDashboardViewStore } from "features/dashboard/stores/sorter-dashboard-view-store";
import { SorterInductionStationViewStore } from "features/induction-station/stores/store-induction-station-view-store";
import { SorterWavesViewStore } from "features/waves/stores/view/sorter-waves-view-store";
import { SorterOrdersViewStore } from "features/wave-detail/stores/view/sorter-orders-view-store";
import { SorterOrderLinesViewStore } from "features/wave-detail/stores/view/sorter-order-lines-view-store";
import { SortPlansViewStore } from "features/sort-plans/stores/sort-plans-view-store";
import { DetailSortPlansViewStore } from "features/sort-plan-detail/stores/detail-sort-plans-store";
import { SettingsSorterViewStore } from "features/settings/stores/settings-view-store";
import { WaveRunningStore } from "./common/wave-running-store";
import { WaveCorrectionsViewStore } from "features/wave-corrections/stores/wave-corrections-view-store";
import { PlatformDiagramStore } from "features/platform/stores/platform-diagram-store";
import { SorterChartsDataProvider } from "features/charts/stores/sorter-charts-data-provider";

export class ViewStore {
  readonly dashboard = new SorterDashboardViewStore();
  readonly charts = new SorterChartsDataProvider();
  readonly inductionStation = new SorterInductionStationViewStore();
  readonly waves = new SorterWavesViewStore();
  readonly orders = new SorterOrdersViewStore();
  readonly orderLines = new SorterOrderLinesViewStore();
  readonly sortPlans = new SortPlansViewStore();
  readonly detailSortPlans = new DetailSortPlansViewStore();
  readonly setting = new SettingsSorterViewStore();
  readonly waveCorrections = new WaveCorrectionsViewStore();
  readonly platform = new PlatformDiagramStore();
}

export class CommonStore {
  readonly waveRunning = new WaveRunningStore();
}

export class SorterRootStore {
  readonly view = new ViewStore();
  readonly common = new CommonStore();
}

export let sorterRootStore = new SorterRootStore();

export function initStores() {
  sorterRootStore = runInAction(() => new SorterRootStore());
}

export default sorterRootStore;
