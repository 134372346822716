import { makeAutoObservable } from "mobx";
import { parse } from "cookie";
import { environment } from "environment";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { IWebsocketTableMessagesModel, WebsocketMessageType, IWebsocketMessageModel, IWebsocketModelHealthMessage, IAPIwaveSorted } from "models/server-models";
import { IClientHealthModel, IWebsocketFatalTableMessagesClientModelContent, IWebsocketTableMessagesClientModelContent } from "models/client-models";
import { serverToClientFatalTableMessageContent, serverToClientHeaderInfoModel, serverToClientHealthModel, serverToClientTableMessageContent, serverToClientWaveControlModel } from "models/mappers";
import alerts from "wes_shell_app/alerts";
import { dataGET, updateItem } from "wes_shell_app/api-utils";

const TOKEN = "wes-token";

interface IAuthTokens {
    accessToken: string;
}

export const getToken = (): IAuthTokens => ({
    accessToken: parse(document.cookie)?.[TOKEN],
});

export class SorterDashboardViewStore {
    private readonly sharedContext: SharedContextStore = new SharedContextStore();

    constructor() {
        makeAutoObservable(this);
    }

    sortedItems = 0;
    totalItems = 0;
    sortedOrders = 0;
    totalOrders = 0;
    bufferItems = 0;
    hospitalItems = 0;
    duration = 0;
    headerInfoName = "-";
    headerInfoStarted = null;
    headerInfoState = "-";
    HeaderInfoID = 0;
    itemsFullnessArray = [0, 0];
    ordersFullnessArray = [0, 0];
    bufferFullnessArray = [0];
    hospitalFullnessArray = [0];
    fatalMessagesCount = 0;
    headerInfoStartedDate = "-";
    waveID = "-";
    status = "";

    fatalMessagesDataTable: IWebsocketFatalTableMessagesClientModelContent[] = [];
    processMessagesDataTable: IWebsocketTableMessagesClientModelContent[] = [];
    technologyMessagesDataTable: IWebsocketTableMessagesClientModelContent[] = [];
    healthMessagesDataTable: IClientHealthModel[] = [];

    waveSorted: boolean | null = null;

    waveControlResponseText: string = "";

    setWaveControlResponseText = (v: string) => (this.waveControlResponseText = v);
    setHeaderInfoID = (v: number) => (this.HeaderInfoID = v);
    setSortedItems = (v: number) => (this.sortedItems = v);
    setTotalItems = (v: number) => (this.totalItems = v);
    setSortedOrders = (v: number) => (this.sortedOrders = v);
    setTotalOrders = (v: number) => (this.totalOrders = v);
    setBufferItems = (v: number) => (this.bufferItems = v);
    setHospitalItems = (v: number) => (this.hospitalItems = v);
    setDuration = (v: number) => (this.duration = v);
    setHeaderInfoName = (v: string) => (this.headerInfoName = v);
    setHeaderInfoStarted = (v: Date | null) => (this.headerInfoStarted = v);
    setHeaderInfoState = (v: string) => (this.headerInfoState = v);
    setItemsFullnessArray = (v: number[]) => (this.itemsFullnessArray = v);
    setOrdersFullnessArray = (v: number[]) => (this.ordersFullnessArray = v);
    setBufferFullnessArray = (v: number[]) => (this.bufferFullnessArray = v);
    setHospitalFullnessArray = (v: number[]) => (this.hospitalFullnessArray = v);
    setHeaderInfoStartedDate = (v: string) => (this.headerInfoStartedDate = v);
    setWaveID = (v: string | number) => (this.waveID = String(v));

    setFatalMessagesCount = (v: number) => (this.fatalMessagesCount = v);
    setFatalMessagesTableData = (v: IWebsocketTableMessagesModel) => {
        this.fatalMessagesDataTable = v.content.map(x => serverToClientFatalTableMessageContent(x, this.getModule(x.appmoduleUuid)?.name));
    }
    setProcessMessagesTableData = (v: IWebsocketTableMessagesModel) => {
        this.processMessagesDataTable = v.content.map(x => serverToClientTableMessageContent(x, this.getModule(x.appmoduleUuid)?.name));
    }
    setTechnologyMessagesTableData = (v: IWebsocketTableMessagesModel) => {
        this.technologyMessagesDataTable = v.content.map(x => serverToClientTableMessageContent(x, this.getModule(x.appmoduleUuid)?.name));
    }

    setHealthMessageData = (v: IWebsocketModelHealthMessage) => {
        this.healthMessagesDataTable = v.message.map(x => serverToClientHealthModel(x));
    };

    setWaveControl = (v: string) => (this.status = v);

    setWaveSorted = (v: boolean | null) => (this.waveSorted = v);

    async waveSortedAPI() {
        try {
            const response: IAPIwaveSorted = await dataGET(`${environment.sorterServiceApi}ui/waveSorted/${this.waveID}`);
            this.setWaveSorted(response.content[0]);
            return true;
        } catch (e) {
            const fixedErrorMessage = (e.message as string).replace('Error: ', '');
            const errorModel = JSON.parse(fixedErrorMessage);
            alerts.error({ message: errorModel.message });
            return false;
        }
    }

    async waveControl(waveID: number | string, action: string) {
        try {
            await updateItem(
                `${environment.sorterServiceApi}ui/commandWave?command=${action}&waveID=${waveID}`
            );
            return {
                success: true,
                action: action,
            };
        } catch (e) {
            const fixedErrorMessage = (e.message as string).replace('Error: ', '');
            const errorModel = JSON.parse(fixedErrorMessage);
            alerts.error({ message: errorModel.message });
            return {
                success: false,
                action: action,
            };;
        }
    };

    //websockets
    wsConnection: WebSocket;
    timeout;

    get isLoaded() { return this.sharedContext.appContext.isContextLoaded; }
    private getModule = (uuid: string) => this.sharedContext.appContext.getModule(uuid);

    private getClientIpAddress = async () => {
        try {
            const response = await fetch("https://ipapi.co/json");
            const result = await response.json();
            return result.ip;
        } catch (e) { }
    };

    openConnection = async () => {
        console.log("openConnection");
        const clientIpAddress = await this.getClientIpAddress();
        this.wsConnection = new WebSocket(
            `${environment.sorterAppWss}${this.sharedContext.appContext.currentStationId}`
        );
        const authMessage = {
            message: "login",
            client: clientIpAddress,
            jwt: getToken().accessToken,
        };

        if (this.wsConnection.readyState === 1) {
            this.wsConnection.send(JSON.stringify(authMessage));
        }

        this.wsConnection.onopen = () => {
            console.log("this.wsConnection.onopen");
            this.wsConnection.send(JSON.stringify(authMessage));
        };

        this.wsConnection.onerror = async (event) => {
            console.log("error", event);
        };

        this.wsConnection.onmessage = async (event) => {
            console.log("this.wsConnection.onmessage");
            // first check if connection is live
            if (this.wsConnection.readyState === 1) {
                const data: IWebsocketMessageModel = JSON.parse(event.data);
                console.log(data);
                if (data.messageType === WebsocketMessageType.headerInfo) {
                    const {
                        id,
                        sortedItems,
                        totalItems,
                        sortedOrders,
                        totalOrders,
                        bufferItems,
                        hospitalItems,
                        duration,
                        name,
                        started,
                        state,
                        itemsFullnessArray,
                        ordersFullnessArray,
                        bufferFullnessArray,
                        hospitalFullnessArray,
                        headerInfoStartedDate,
                        waveID
                    } = serverToClientHeaderInfoModel(data.message[0]);
                    this.setHeaderInfoID(id)
                    this.setSortedItems(sortedItems);
                    this.setTotalItems(totalItems);
                    this.setSortedOrders(sortedOrders);
                    this.setTotalOrders(totalOrders);
                    this.setBufferItems(bufferItems);
                    this.setHospitalItems(hospitalItems);
                    this.setDuration(duration);
                    this.setHeaderInfoName(name);
                    this.setHeaderInfoStarted(started);
                    this.setHeaderInfoState(state);
                    this.setItemsFullnessArray(itemsFullnessArray);
                    this.setOrdersFullnessArray(ordersFullnessArray);
                    this.setBufferFullnessArray(bufferFullnessArray);
                    this.setHospitalFullnessArray(hospitalFullnessArray);
                    this.setHeaderInfoStartedDate(headerInfoStartedDate);
                    this.setWaveID(waveID);
                } else if (data.messageType === WebsocketMessageType.fatal) {
                    const { messageCount, message } = data;
                    this.setFatalMessagesCount(messageCount);
                    this.setFatalMessagesTableData(message);
                } else if (data.messageType === WebsocketMessageType.process) {
                    this.setProcessMessagesTableData(data.message);
                } else if (data.messageType === WebsocketMessageType.technical) {
                    this.setTechnologyMessagesTableData(data.message);
                } else if (data.messageType === WebsocketMessageType.health) {
                    this.setHealthMessageData(data);
                } else if (data.messageType === WebsocketMessageType.waveControl) {
                    const { status } = serverToClientWaveControlModel(data.message);
                    this.setWaveControl(status);
                }
            }
        }
    };

    closeConnection = () => {
        if (this.wsConnection) {
            this.wsConnection.close();
        }
    };
}