import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSortPlansStore } from "features/sort-plans/hooks/use-sort-plans-store";
import { getTranslation } from "wes_shell_app/localization-utils";

const SortPlansReplacedContainers = () => {
  const store = useSortPlansStore();
  const replacedContainers = store.replacedContainers;

  const columns = [];

  return (
    <TableContainer sx={{ marginTop: columns.length > 0 ? "0" : "1rem" }}>
      <Table className="relative">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <strong>{getTranslation("name")}</strong>
            </TableCell>
            <TableCell align="left">
              <strong>{getTranslation("container")}</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <Divider />
        <TableBody>
          {replacedContainers.map((container, index) => (
            <TableRow key={index}>
              <TableCell align="left">{container.name}</TableCell>
              <TableCell align="left">{container.container}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SortPlansReplacedContainers;
