import { environment } from "environment";
import { ISortPlansFilterParams } from "features/sort-plans/utils/sort-plans-utils";
import { makeObservable, observable } from "mobx";
import { IClientSorterPlanModel } from "models/client-models";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class DetailSortPlansDataTableProvider extends DataTableStore<any, ISortPlansFilterParams> {
    readonly sharedContextStore = new SharedContextStore();

    constructor() {
        super();
        makeObservable(this, {
            selectedSortPlan: observable,
        });
    }
    
    get endpointUrl(): string { return `ui/getSortPlanDetails/${this.selectedSortPlan?.id}`; }
    protected serviceApiUrl = environment.sorterServiceApi;
    
    selectedSortPlan: IClientSorterPlanModel | null = null;

    set setSelectSortPlan(selectedSortPlan: IClientSorterPlanModel){
        this.selectedSortPlan = selectedSortPlan;
    }

    get defaultFilter() {
        return new Map<any, any>([
            ['state', 'active'],
        ]);
    }

    get defaultTimeFromValue() {
        let timeFrom = new Date();
        timeFrom.setHours(timeFrom.getHours() - 1);
        return timeFrom;
    }
}