import { environment } from "environment";
import { IWaveCorrectionsFilterParams } from "features/wave-corrections/utils/wave-corrections-utils";
import { WaveRunningStore } from "stores/common/wave-running-store";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class WaveCorrectionsDataTableProvider extends DataTableStore<any, IWaveCorrectionsFilterParams> {
    readonly sharedContextStore = new SharedContextStore();
    readonly waveRunnigStore = new WaveRunningStore();

    private waveRunningId: number = 0;

    constructor() {
        super();
        this.getWaves();
    }

    async getWaves() {
        await this.waveRunnigStore.getWaveRunning();
        this.waveRunningId = this.waveRunnigStore.waveRunning?.id ? this.waveRunnigStore.waveRunning.id : 0;
    }

    setWaveRunningIdAndReload(newWaveRunningId: number) {
        if (!this.sharedContextStore.appContext.accessRights.isAdminRole) {
            return;
        }
        this.waveRunningId = newWaveRunningId;
        this.forceReload();
    }

    get endpointUrl() {
        return `ui/getWaveItems/${this.waveRunningId}`
    }

    protected serviceApiUrl = environment.sorterServiceApi;

    get defaultTimeFromValue() {
        let timeFrom = new Date();
        timeFrom.setHours(timeFrom.getHours() - 1);
        return timeFrom;
    }
}