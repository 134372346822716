import { observer } from "mobx-react";
import React from "react";
import BaseView from "wes_shell_app/base-view";
import { WavesDataTable } from "../components/waves-data-table";
import { WavesDataTableFilter } from "../components/waves-data-table-filter";
import SharedContextStore from "wes_shell_app/shared-context-store";

const WavesView = observer(() => {
  const sharedContextStore = new SharedContextStore();
  const UserAccess =
    sharedContextStore.appContext.accessRights.getUserAccessEnums;

  return (
    <BaseView accessKey={UserAccess.SORTER_NAV_ITEM}>
      <WavesDataTableFilter />
      <WavesDataTable />
    </BaseView>
  );
});

export default WavesView;
