import { observer } from "mobx-react";
import { Drawer } from "wes_shell_app/drawer";
import { useOrderLinesStore } from "../hooks/use-order-lines-store";
import { OrderLinesDataTable } from "./order-lines-data-table";
import { OrderLinesDataTableFilter } from "./order-lines-data-table-filter";

export const OrderLinesDrawer = observer(() => {
  const store = useOrderLinesStore();
  return (
    <Drawer
      anchor="right"
      onClose={store.closeDrawer}
      open={store.isDrawerOpened}
      sx={{ maxWidth: "90vw" }}
    >
      <OrderLinesDataTableFilter />
      <OrderLinesDataTable />
    </Drawer>
  );
});
