type CardTableProps = {
  data: (string | number)[];
  title: string[];
  sufix?: string[];
};

const CardTable = (props: CardTableProps) => {
  return (
    <div className="card-table">
      <table>
        <tbody>
          {props.title.map((title, index) => (
            <tr key={index}>
              <td>{`${title}:`}</td>
              <td className="pl-4 font-semibold">
                {props.data[index]} {props.sufix ? props.sufix[index] : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CardTable;
