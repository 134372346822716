import { MobXProviderContext } from 'mobx-react';
import * as React from 'react';
import { SorterRootStore } from 'stores/sorter-root-store';

type IAppMobxContext = {
  rootStore: SorterRootStore;
}

export function useSorterAppMobxContext() {
  return React.useContext<IAppMobxContext>(MobXProviderContext as any);
}