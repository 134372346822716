import { Close } from "@mui/icons-material";
import Button from "wes_shell_app/button";
import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";

const items: SelectItemType[] = [
  {
    contentIntl: "runningState",
    id: "running",
  },
  {
    contentIntl: "completedState",
    id: "completed",
  },
  {
    contentIntl: "createdState",
    id: "created",
  },
  {
    contentIntl: "deletedState",
    id: "deleted",
  },
  {
    contentIntl: "stoppedState",
    id: "stopped",
  },
  {
    contentIntl: "prepareRunState",
    id: "prepareRun",
  },
  {
    contentIntl: "prepareResumeState",
    id: "prepareResume",
  },
];

type IActiveFilterSelectorProps = {
  value: string;
  onChange: (v: string) => void;
  onClear: () => void;
};

export const StateSelector = ({
  value,
  onChange,
  onClear,
}: IActiveFilterSelectorProps) => {
  const onChangeHandler = (v: string) => onChange(v);

  return (
    <Select
      fullWidth
      intlId="stateSelector"
      placeholder="stateSelector"
      items={items}
      value={value ? `${value}` : undefined}
      onChange={onChangeHandler}
      endAdornment={
        value ? (
          <div className="mr-4 flex align-center">
            <Button variant="small-icon" onClick={onClear}>
              <Close fontSize="small" color="action" />
            </Button>
          </div>
        ) : undefined
      }
    />
  );
};
