import Select from "wes_shell_app/select";
import { isUndefined } from "lodash";
import { SelectItemType } from "wes_shell_app/types";

type IActiveFilterSelectorProps = {
  value: string;
  items: SelectItemType[];
  formSizeSmall?: boolean;
  onChange: (value: string) => void;
};

const SortPlansTableRowSelector = (props: IActiveFilterSelectorProps) => {
  const { value, onChange, items, formSizeSmall } = props;

  const onChangeHandler = (v: string) => onChange(v);

  return (
    <Select
      fullWidth
      items={items}
      value={!isUndefined(value) ? `${value}` : undefined}
      onChange={onChangeHandler}
      formSizeSmall={formSizeSmall}
    />
  );
};

export default SortPlansTableRowSelector;
