import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useSortPlansStore } from "features/sort-plans/hooks/use-sort-plans-store";
import { Checkbox } from "wes_shell_app/checkbox";
import { getTranslation } from "wes_shell_app/localization-utils";

const SortPlansWaveData = () => {
  const store = useSortPlansStore();
  const waveSummary = store.waveSummary;

  return (
    <TableContainer style={{ width: "auto", margin: "20px" }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>
              {`${getTranslation("selectedWaveToRun")}`}
            </TableCell>
            <TableCell>{waveSummary[0]?.waveName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>{`${getTranslation(
              "waveType"
            )}:`}</TableCell>
            <TableCell>{waveSummary[0]?.waveType}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>
              {`${getTranslation("ordersInWave")}`}
            </TableCell>
            <TableCell>{waveSummary[0]?.waveOrders}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>
              {`${getTranslation("locationsInWave")}`}
            </TableCell>
            <TableCell>{waveSummary[0]?.waveLocations}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>
              {`${getTranslation("selectedSortPlan")}`}
            </TableCell>
            <TableCell>{waveSummary[0]?.sortPlanName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={2} align="right">
              {`${waveSummary[0]?.total} ${getTranslation("total")}:`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={2} align="right">
              {`${waveSummary[0]?.avbSort} ${getTranslation("toSort")}:`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>
              {`${getTranslation("sortingOutputs")}`}
            </TableCell>
            <TableCell colSpan={2} align="right">
              {`${waveSummary[0]?.noPhysLoc} ${getTranslation(
                "noPhysLocationOutputs"
              )}:`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={2} align="right">
              {`${waveSummary[0]?.noContainer} ${getTranslation(
                "noContainerOutputs"
              )}:`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={2} align="right">
              {`${waveSummary[0]?.full} ${getTranslation("full")}`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>
              {`${getTranslation("autoAssignPhysLoc")}`}
            </TableCell>
            <TableCell>
              <Checkbox
                value={store.automaticPhysicalAssignment}
                label={""}
                onChange={(v) => {
                  store.setAutomaticPhysicalAssignment();
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SortPlansWaveData;
