import { FormLabel, TextField } from "@mui/material";
import Button from "wes_shell_app/button";
import LocalizedText from "wes_shell_app/localized-text";
import { BarcodeAction } from "../hooks/use-hand-barcode-scanner";
import { RadioGroup } from "wes_shell_app/radio-group";
import { useInductionStationViewStore } from "../hooks/use-induction-station-view-store";
import { observer } from "mobx-react";
import { useState } from "react";
import React from "react";
import { IWebsocketBarcodeReadInfoModel } from "models/server-models";

const BarcodeScannerComponent = observer(() => {
  const store = useInductionStationViewStore();
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [selectedValue, setSelectedValue] = useState("");

  const handleButton = (barcode: string) => {
    const model: IWebsocketBarcodeReadInfoModel = {
      sorterid: store.actualInductionStation?.sorterID,
      barcodes: [barcode],
      timestamp: new Date(),
      indupointId: parseInt(store.handleHandScanBarcode[1]),
    };
    store.setbarCodeReadInfo(model);
    store.resetHandleHandScanBarcode();
  };

  return (
    <div className="flex flex-col">
      <FormLabel className="text-xs" sx={{ fontSize: "0.75em" }}>
        <LocalizedText intlId="manualScan" />
      </FormLabel>
      {store.actualIndupoints?.length > 1 && (
        <div className="flex">
          <RadioGroup
            values={store.actualIndupoints?.map((indupoint) => ({
              label: indupoint.indupointName,
              value: indupoint.indupointId,
              checked: selectedValue === indupoint.indupointId.toString(),
              disabled: !indupoint.opened,
            }))}
            row={true}
            onChange={(value) => {
              setSelectedValue(value);
              store.setHandleHandScanBarcode([
                BarcodeAction.handScanBarcode,
                value,
              ]);
            }}
            radioSize={"small"}
            formControlLabelSX={{ fontSize: "15px" }}
          />
        </div>
      )}
      <div
        className={`flex gap-2 ${store.actualIndupoints?.length < 2 && "mt-2"}`}
      >
        <TextField
          id="outlined-basic"
          label="Enter barcode"
          variant="outlined"
          inputRef={inputRef}
        />
        <Button
          onClick={() => {
            handleButton(inputRef.current?.value);
            inputRef.current!.value = "";
            setSelectedValue("");
          }}
          variant="contained"
          disabled={
            store.actualIndupoints?.length < 2
              ? false
              : store.handleHandScanBarcode.length > 0 &&
                store.handleHandScanBarcode[0] === BarcodeAction.handScanBarcode
              ? false
              : true
          }
        >
          Scan
        </Button>
      </div>
    </div>
  );
});

export default BarcodeScannerComponent;
