import { FormLabel } from "@mui/material";
import LocalizedText from "wes_shell_app/localized-text";
import React from "react";

type CardProps = {
  intlId: string;
  mainText?: string;
  subTitleFirst?: string;
  subTextFirst?: string;
  subTextSecond?: string;
  mainTextSecond?: string;
  icon?: React.ReactNode;
  className?: string;
};

const Card = ({
  intlId,
  mainText,
  subTitleFirst,
  subTextFirst,
  subTextSecond,
  mainTextSecond,
  icon,
  className,
}: CardProps) => {
  return (
    <>
      <FormLabel className="text-xs" sx={{ fontSize: "0.75em" }}>
        <LocalizedText intlId={intlId} />
      </FormLabel>
      {icon ? (
        <div className="flex justify-center">{icon}</div>
      ) : (
        <div className={className}>
          <div className={`flex flex-row`}>
            <p
              className="text-xl font-bold mt-3"
              dangerouslySetInnerHTML={{ __html: mainText }}
            ></p>
            <p
              className="text-xl font-bold mt-3"
              dangerouslySetInnerHTML={{ __html: mainTextSecond }}
            ></p>
          </div>
          <div className="flex justify-between">
            <p className="mt-2">
              {subTitleFirst && `${subTitleFirst}:`}{" "}
              {subTextFirst && `${subTextFirst}`}
            </p>
            <p className="mt-2">{subTextSecond && `${subTextSecond}`}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
