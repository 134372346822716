import { IClientWaveCorrectionModel } from "models/client-models";
import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";
import { isUndefined } from "lodash";

type IActiveFilterSelectorProps = {
  value: number;
  formSizeSmall?: boolean;
  onChange: (value: string) => void;
  item: IClientWaveCorrectionModel;
};

const WaveCorrectionsTableRowSelector = (props: IActiveFilterSelectorProps) => {
  const { value, onChange, item, formSizeSmall } = props;

  const maxQuantity = item.totalQuantity;

  const onChangeHandler = (value: string) => onChange(value);

  const items: SelectItemType[] = Array.from(
    { length: maxQuantity + 1 },
    (_, index) => ({
      id: index,
      content: index,
    })
  );

  return (
    <Select
      fullWidth
      items={items}
      value={!isUndefined(value) ? `${value}` : undefined}
      onChange={onChangeHandler}
      formSizeSmall={formSizeSmall}
    />
  );
};

export default WaveCorrectionsTableRowSelector;
