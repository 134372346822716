import React from "react";
import RestartModule from "../components/settings-restart-module";
import { SettingsSorterViewStore } from "../stores/settings-view-store";
import SettingServicesTesting from "../components/settings-services-testing";

export type SettingActiveComponent = {
  title: string;
  content: React.ReactNode;
  id: number;
};

const SettingView = (
  store: SettingsSorterViewStore
): SettingActiveComponent[] => {
  let id = 0;

  const generateId = () => {
    return id++;
  };

  return [
    {
      title: "modulsRestarting",
      content: <RestartModule store={store} />,
      id: generateId(),
    },
    {
      title: "componentsServicesTesting",
      content: <SettingServicesTesting store={store} />,
      id: generateId(),
    },
  ];
};

export default SettingView;
