import React from "react";
import { Dialog } from "common/base-components/dialog/dialog";
import DialogRestartModuleMessage from "./content/settings-dialog-restart-module-message";

interface SettingsRestartModuleDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const SettingsRestartModuleDialog: React.FC<
  SettingsRestartModuleDialogProps
> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      intlTitle="modulsRestart"
      cancelAction={onClose}
      onClose={onClose}
      confirmAction={onConfirm}
    >
      <DialogRestartModuleMessage />
    </Dialog>
  );
};

export default SettingsRestartModuleDialog;
