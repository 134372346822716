import { Close } from "@mui/icons-material";
import { isUndefined } from "lodash";
import Button from "wes_shell_app/button";
import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";

type IActiveFilterSelectorProps = {
  value: string;
  intld: string;
  placeholder: string;
  onChange: (v: string) => void;
  onClear: () => void;
  items: SelectItemType[];
};

export const FilterSelector = (props: IActiveFilterSelectorProps) => {
  const { value, intld, placeholder, onChange, onClear, items } = props;
  const onChangeHandler = (v: string) => onChange(v);

  return (
    <Select
      fullWidth
      intlId={intld}
      placeholder={placeholder}
      items={items}
      value={!isUndefined(value) ? `${value}` : undefined}
      onChange={onChangeHandler}
      endAdornment={
        !isUndefined(value) ? (
          <div className="mr-4 flex align-center">
            <Button variant="small-icon" onClick={onClear}>
              <Close fontSize="small" color="action" />
            </Button>
          </div>
        ) : undefined
      }
    />
  );
};
