import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import Text from "wes_shell_app/text";
import { useSorterWavesStore } from "../../hooks/use-waves-store";
import { CheckCircleOutline } from "@mui/icons-material";
import { Stack } from "@mui/material";

type waveStartDialogProps = {
  isOpened: boolean;
  onClose: () => void;
};

export const RunWaveSuccessDialog = observer((props: waveStartDialogProps) => {
  const store = useSorterWavesStore();
  return (
    <Dialog
      intlTitle="waveIsRunningTitle"
      open={props.isOpened}
      onClose={props.onClose}
    >
      <Stack alignItems="center">
        <CheckCircleOutline
          fontSize="large"
          color="success"
          sx={{ transform: "scale(4)", margin: "4rem" }}
        />
        <Text
          intlId="waveIsRunningContent"
          intlParams={{ sortPlan: store.runWaveStore.selectedSortPlanId }}
        />
      </Stack>
    </Dialog>
  );
});
