import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import { IWaveCorrectionsFilterParams } from "../utils/wave-corrections-utils";
import { WaveCorrectionsDataTableProvider } from "./data/wave-corrections-data-table-provider";
import { IServerWaveCorrectionsModel } from "models/server-models";
import { IClientWaveCorrectionModel } from "models/client-models";
import { computed, makeObservable } from "mobx";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { updateItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import alerts from "wes_shell_app/alerts";
import { serverToClientWaveCorrectionModel } from "models/mappers";

export class WaveCorrectionsViewStore extends BaseTableViewStore<
    IServerWaveCorrectionsModel,
    IClientWaveCorrectionModel,
    IWaveCorrectionsFilterParams,
    WaveCorrectionsDataTableProvider> {
    constructor() {
        super(new WaveCorrectionsDataTableProvider());
        makeObservable(this, {
            currentUserStore: computed,
            isAdmin: computed,
            isLoaded: computed,
            defaultTimeFromValue: computed,
        });
    }

    async updateWaveItemAPI(id: number, lineID: number, quantity: string) {
        try {
            await updateItem(`${environment.sorterServiceApi}ui/updateWaveItem?itemID=${id}&lineID=${lineID}&quantity=${quantity}`);
            return true;
        } catch (e) {
            const fixedErrorMessage = (e.message as string).replace('Error: ', '');
            const errorModel = JSON.parse(fixedErrorMessage);
            alerts.error({ message: errorModel.message });
            return false;
        }
    }

    providerforceReload(id: number) {
        this.tableProvider.setWaveRunningIdAndReload(id);
    }

    mapServerToClientModel = (item): IClientWaveCorrectionModel =>
        serverToClientWaveCorrectionModel(item);

    private readonly appContext = new SharedContextStore();

    get currentUserStore() { return this.appContext.appContext.accessRights; }
    get isAdmin() { return this.currentUserStore.isAdminRole || false; }
    get isLoaded() { return this.currentUserStore.isLoaded && this.tableProvider.isLoaded; }
    get defaultTimeFromValue() { return this.tableProvider.defaultTimeFromValue; }
}