import { Box } from "@mui/material";
import { useSortPlansStore } from "../hooks/use-sort-plans-store";
import { observer } from "mobx-react";
import { FilterSelector } from "common/common-components/selectors/filter-selector";
import { SelectItemType } from "wes_shell_app/types";

type IDataTableFilterProps = {
  actionContent?: React.ReactElement;
  cloneActionContent?: React.ReactElement;
};

const SortPlansDataTableFilter = observer(
  ({ actionContent, cloneActionContent }: IDataTableFilterProps) => {
    const store = useSortPlansStore();

    const showCloneButton = store.isAdmin && store.showedSortPlanCloneButton;

    const StateItems: SelectItemType[] = [
      {
        contentIntl: "statusActive",
        id: "active",
      },
      {
        contentIntl: "statusDeleted",
        id: "deleted",
      },
    ];

    return (
      <Box className="flex flex-row justify-between space-x-2 w-full mt-2 mb-8 items-end">
        <div className="flex flex-row w-48">
          <FilterSelector
            intld={"status"}
            placeholder={"status"}
            value={store.getFilterValue("state")}
            items={StateItems}
            onChange={(v) => store.setFilter("state", v)}
            onClear={() => store.clearFilter("state")}
          />
        </div>
        <div className="flex gap-2">
          {showCloneButton && (
            <div style={{ marginLeft: "auto" }}>{cloneActionContent}</div>
          )}
          <div style={{ marginLeft: "auto" }}>{actionContent}</div>
        </div>
      </Box>
    );
  }
);

export default SortPlansDataTableFilter;
