import { useState, useEffect } from "react";
import BaseView from "wes_shell_app/base-view";
import PlatformMap from "../components/platform-map";
import { usePlatformStore } from "../hooks/use-platform-store";
import Platformlegend from "../components/platform-legend";

const PlatformView = () => {
  const [showComponent, setShowComponent] = useState(false);
  const store = usePlatformStore();

  useEffect(() => {
    store.openConnection();

    return () => {
      store.closeConnection();
    };
  }, [store]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 20);

    return () => clearTimeout(timer);
  }, []);

  return (
    <BaseView heightFull={true}>
      {showComponent && (
        <div className="relative">
          <div className="absolute top right-0 flex gap-4 items-center justify-items-center z-50">
            <Platformlegend />
          </div>
          <PlatformMap />
        </div>
      )}
    </BaseView>
  );
};

export default PlatformView;
