import React from "react";
import { AppProvider } from "./providers/app-provider";
import { SorterRoutes } from "routing/sorter-routes";
import { setSorterTranslation } from "localization/sorter-localization";
import { Alerts } from "wes_shell_app/alerts";

function App() {
  React.useMemo(() => {
    setSorterTranslation();
  }, []);

  return (
    <AppProvider>
      <Alerts />
      <SorterRoutes />
    </AppProvider>
  );
}

export default App;
