import { IAPISettingComponentsContent } from "models/server-models";
import Text from "wes_shell_app/text";
import Small from "wes_shell_app/small";

type ISettingsDialogServicesTest = {
  component: IAPISettingComponentsContent | null;
};

const SettingsDialogServicesTest = (props: ISettingsDialogServicesTest) => {
  const { description } = props.component;

  return (
    <div>
      <Text intlId="componentsInfromation"/>
      <Small intlId={description || "componentsNothingInformation"} />
    </div>
  );
};

export default SettingsDialogServicesTest;
