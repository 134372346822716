import { environment } from "environment";
import { makeAutoObservable } from "mobx";
import {
  IAPIChangeContainerModel,
  IAPICheckUnsortedOrders,
  IAPICheckUnsortedOrdersContent,
  IAPICheckWaveRunningContent,
  IAPICheckWaveRunningModel,
  IAPIMessageModel,
  IAPIPhysicalLocationsModel,
  IAPIRunWaveResponseModel,
  IAPISortingOutputsModel,
  IAPIWaveSummaryModel,
  IServerRequestSortPlansModel,
} from "models/server-models";
import { dataGET, updateItem } from "wes_shell_app/api-utils";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class SorterRunWaveStore {
  constructor() {
    makeAutoObservable(this);
  }

  private readonly sharedContextStore = new SharedContextStore();
  protected serviceApiUrl = environment.sorterServiceApi;
  get sorterId() {
    return this.sharedContextStore.appContext.currentStationId;
  }

  async init() {
    await this.refreshRunningWave();
    await this.refreshUnsortedOrders();
    await this.refreshSortPlans();
  }

  selectedWaveId: number;
  setSelectedWaveId(id: number) {
    this.selectedWaveId = id;
  }
  selectedSortPlanId: number;
  setSelectedSortPlanId(id: number) {
    this.selectedSortPlanId = id;
  }
  addUnsortedOrders: boolean = false;
  setAddUnsortedOrders(v: boolean) {
    this.addUnsortedOrders = v;
  }
  autoPhysAssignment: boolean = false;
  setAutoPhysAssignment(v: boolean) {
    this.autoPhysAssignment = v;
  }

  runningWave: IAPICheckWaveRunningContent;
  async refreshRunningWave() {
    try {
      const response: IAPICheckWaveRunningModel = await dataGET(
        `${this.serviceApiUrl}ui/checkWaveRunning/${this.sorterId}`
      );
      this.runningWave = response.content[0];
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  unsortedOrders: IAPICheckUnsortedOrdersContent;
  async refreshUnsortedOrders() {
    try {
      const response: IAPICheckUnsortedOrders = await dataGET(
        `${this.serviceApiUrl}ui/checkUnsortedOrders/${this.sorterId}`
      );
      this.unsortedOrders = response.content[0];
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  physicalLocations: IAPIPhysicalLocationsModel;
  async refreshPhysicalLocations() {
    try {
      const response: IAPIMessageModel<IAPIPhysicalLocationsModel> =
        await dataGET(
          `${this.serviceApiUrl}ui/checkLocationsForWave?waveID=${this.selectedWaveId}&sortPlanID=${this.selectedSortPlanId}&sorterID=${this.sorterId}`
        );
      this.physicalLocations = response.content[0];
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  sortingOutputs: IAPISortingOutputsModel;
  async refreshSortingOutputs() {
    try {
      const response: IAPIMessageModel<IAPISortingOutputsModel> = await dataGET(
        `${this.serviceApiUrl}ui/checkSortingOutputs/${this.sorterId}`
      );
      this.sortingOutputs = response.content[0];
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  containersToReplace: IAPIChangeContainerModel[];
  async refreshContainersToReplace() {
    try {
      const response: IAPIMessageModel<IAPIChangeContainerModel> =
        await dataGET(
          `${this.serviceApiUrl}ui/checkReplacedContainers?sortPlanID=${this.selectedSortPlanId}&sorterID=${this.sorterId}`
        );
      this.containersToReplace = response.content;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  availableSortPlans: IServerRequestSortPlansModel[];
  async refreshSortPlans() {
    try {
      const response: { content: IServerRequestSortPlansModel[] } =
        await dataGET(
          `${this.serviceApiUrl}ui/getActiveSortPlans/${this.sorterId}`
        );
      this.availableSortPlans = response.content;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  waveSummary: IAPIWaveSummaryModel;
  async refreshWaveSummary() {
    try {
      const response: IAPIMessageModel<IAPIWaveSummaryModel> = await dataGET(
        `${this.serviceApiUrl}ui/getWaveSummary?sortPlanID=${this.selectedSortPlanId}&waveID=${this.selectedWaveId}`
      );
      this.waveSummary = response.content[0];
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async runWave() {
    const response: IAPIRunWaveResponseModel = await updateItem(
      `${this.serviceApiUrl}ui/waveStart?sorterID=${this.sorterId}&sortPlanID=${this.selectedSortPlanId}&waveID=${this.selectedWaveId}&autoPhysAssignment=${this.autoPhysAssignment}&addUnsortedOrders=${this.addUnsortedOrders}`
    );
    if (response.content && !response.content[0].resultOk) {
      throw new Error(response.content[0].message);
    } else {
      return response;
    }
  }
}
