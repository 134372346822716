/**
 * Models of all data comming from server via API.
 * Type declarations according to Swagger.
 */

import { IWithId } from "wes_shell_app/api-types";
import { Severity } from "wes_shell_app/types";

export type IServerCreateTransportRequestModel = IWithId & {
  stationId: number;
  sourceWorkplaceId: number;
  targetWorkplaceId: number;
  sourcePositionUuid: string;
  targetPositionUuid: string;
  sourceUuids: string[];
  targetUuids: string[];
  queue: any;
  priority: number;
  content: string[];
  skipOccupancyCheck?: boolean;
};

export type IServerSortPlansModel = IWithId & {
  state: string;
  name: string;
  created: Date;
  updated: Date;
};

export type IServerDetailSortPlansModel = IWithId & {
  level: number;
  finger: string;
  state: string;
  destination: string;
  phylocationid: number;
  created: Date;
  updated: Date;
  output: string;
  type: string;
  side: string;
};

export type IServerCreateSortPlansModel = IWithId & {
  name: string;
  state: string;
};

export type IServerRequestSortPlansModel = IWithId & {
  name: string;
  state: string;
  created: Date;
  updated: Date;
};

export type IServerRequestDetailSortPlansModel = IWithId & {
  level: number;
  finger: string;
  state: string;
  destination: string;
  phylocationid: number;
  created: Date;
  updated: Date;
  output: string;
  type: string;
  side: string;
};

export type IServerCreateSortPlansRequestModel = IWithId & {
  name: string;
  state: string;
  created: string;
  updated: string;
  priority: number;
  stationId: number;
};

export enum WebsocketMessageType {
  headerInfo = "headerInfo",
  fatal = "fatal",
  process = "process",
  technical = "technical",
  health = "health",
  waveControl = "waveControl",
  waveInfo = "waveInfo",
  barcodeReadInfo = "barcodeReadInfo",
  notificationInfo = "notificationInfo",
  cameraImageAvailable = "cameraImageAvailable",
  mapUpdate = "mapUpdate",
}

export type IWebsocketMessageModel =
  | IWebsocketModelHeaderInfoMessage
  | IWebsocketFatalMessageModel
  | IWebsocketRobotUtilizationModelProcessMessage
  | IWebsocketRobotUtilizationModelTechnicalMessage
  | IWebsocketModelHealthMessage
  | IWebsocketWaveControlMessage
  | IAPIWaveInfoMessage
  | IWebsocketModelBarcodeReadInfoMessage
  | IWebsocketNotificationInfoMessage
  | IWebsocketCameraImageAvailableMessage
  | IWebsocketModelMapUpdateMessage;

export type IWebSocketHeaderInfoModel = {
  id: number;
  sortedItems: number;
  totalItems: number;
  sortedOrders: number;
  totalOrders: number;
  bufferItems: number;
  hospitalItems: number;
  duration: number;
  name: string;
  started: Date;
  state: string;
};

export type IWebsocketModelHeaderInfoMessage = {
  message: IWebSocketHeaderInfoModel[];
  messageType: WebsocketMessageType.headerInfo;
};

export type IWebsocketTableMessagesServerModelContent = {
  id: number;
  issued: string;
  severity: Severity;
  confirmed: boolean;
  message: string;
  appmoduleUuid: string;
};

export type IWebsocketTableMessagesModel = {
  content: IWebsocketTableMessagesServerModelContent[];
};

export type WebsocketMessageCount = {
  count: number;
};

export type IWebsocketFatalMessageModel = {
  message: IWebsocketTableMessagesModel;
  messageCount: number;
  messageType: WebsocketMessageType.fatal;
};

export type IWebsocketRobotUtilizationModelProcessMessage = {
  message: IWebsocketTableMessagesModel;
  messageType: WebsocketMessageType.process;
};

export type IWebsocketRobotUtilizationModelTechnicalMessage = {
  message: IWebsocketTableMessagesModel;
  messageType: WebsocketMessageType.technical;
};

export type IWebSocketHealthModel = {
  id: number;
  name: string;
  health: string;
};

export type IWebsocketModelHealthMessage = {
  message: IWebSocketHealthModel[];
  messageType: WebsocketMessageType.health;
};

export type IWebsocketWaveControlModel = {
  status: string;
};

export type IWebsocketWaveControlMessage = {
  message: IWebsocketWaveControlModel;
  messageType: WebsocketMessageType.waveControl;
};

export type IAPICheckWaveRunningContent = {
  id: number;
  name: string;
};

export type IAPICheckWaveRunningModel = {
  status: boolean;
  message: string;
  content: IAPICheckWaveRunningContent[];
};

export type IAPIInductionStationContent = {
  sorterid: number;
  sorterName: string;
  inductionStations: IAPIInductionStationInductionContent[];
};

export type IAPIInductionStationInductionContent = {
  inductionID: number;
  inductionName: string;
  sorterID: number;
  indupoints: IAPIInductionStationInductionPointContent[];
};

export type IAPIInductionStationInductionPointContent = {
  indupointId: number;
  indupointName: string;
};

export type IAPIInductionStationModel = {
  status: boolean;
  message: string;
  content: IAPIInductionStationContent[];
};

export type IAPIWaveInfoMessage = {
  message: IAPIWaveInfoModel;
  messageType: WebsocketMessageType.waveInfo;
};

export type IAPIWaveInfoModel = {
  level: number;
  name: string;
  sorter: string;
  sorterid: number;
  state: string;
  timestamp: Date;
  waveid: number;
};

export type IWebsocketModelBarcodeReadInfoMessage = {
  message: IWebsocketBarcodeReadInfoModel;
  messageType: WebsocketMessageType.barcodeReadInfo;
};

export type IWebsocketBarcodeReadInfoModel = {
  message?: string;
  sorterid: number;
  induction?: string;
  indupoint?: string;
  indupointId: number;
  barcodes: string[];
  timestamp: Date;
};

export type IWebsocketNotificationInfoMessage = {
  message: IWebsocketNotificationInfoModel;
  messageType: WebsocketMessageType.notificationInfo;
};

export type IWebsocketNotificationInfoModel = {
  sorterid: number;
  type: string;
  robotArrived?: IWebsocketNotificationRobotArrivedModel;
  robotDepartured?: IWebsocketNotificationRobotDeparturedModel;
  testBarcodes?: string[];
  testRunning?: boolean;
  timestamp: Date;
};

export type IWebsocketNotificationRobotArrivedModel = {
  induction: string;
  indupoint: string;
  indupointId: number;
  robotid: string;
};

export type IWebsocketNotificationRobotDeparturedModel = {
  induction: string;
  indupoint: string;
  indupointId: number;
  robotid: string;
  errno: number;
  errmsg: string;
};

export type IWebsocketCameraImageAvailableMessage = {
  messageType: WebsocketMessageType.cameraImageAvailable;
  message: IWebsocketCameraImageAvailableContent;
};

export type IWebsocketCameraImageAvailableContent = {
  content: IWebsocketCameraImageAvailableModel[];
};

export type IWebsocketCameraImageAvailableModel = {
  cameraId: number;
};

export type IAPIBarcodeLinesModel = {
  lineid: number;
  id: string;
  name: string;
  barcode: string;
  quantity: number;
  sortedqty: number;
  remainingqty: number;
  hazmat: boolean;
  fragile: boolean;
  weight: number;
  length: number;
  width: number;
  height: number;
  orderid: number;
  orderextid: string;
  order: string;
  phylocationid: number;
  phylocation: string;
  phylocationextid: string;
  destinations: string;
  itemid: number;
  robotid: string;
};

export type IAPIBarcodeModel = {
  errno: number;
  message: string;
  waveid: number;
  count: number;
  indupoint: string;
  indupointId: number;
  lines: IAPIBarcodeLinesModel[];
  action: string;
  reason: string;
  timestamp: string;
};

export type IAPIInductionStateModel = {
  timestamp: string;
  indupoints: IAPIInductionStatePointModel[];
};

export type IAPIInductionStatePointModel =
  IAPIInductionStationInductionPointContent & {
    open: boolean;
    cameraImageAllowed: boolean;
    cameraId: number;
  };

export type IAPISortModel = {
  errno: number;
  message: string;
  sorterid: number;
  indupointId: number;
  waveid: number;
  orderid: number;
  lineid: number;
  destinationid: number;
  phylocationid: number;
  name: string;
  barcode: string;
  externalid: string;
  order: string;
  destination: string;
  phylocation: string;
  taskid: number;
  phyname: string;
  phyaddress: string;
  timestamp: string;
};

export type IWaveState =
  | "running"
  | "completed"
  | "created"
  | "deleted"
  | "stopped"
  | "prepareRun"
  | "prepareResume";
export type IWaveType = "single" | "infinite";

export type IAPIWaveModel = IWithId & {
  state: IWaveState;
  type: IWaveType;
  name: string;
  started: Date;
  completed: Date;
  created: Date;
};

export type IOrderState = "created" | "completed" | "deleted" | "sorting";
export type IAPIOrderModel = IWithId & {
  state: IOrderState;
  name: string;
  externalid: string;
  phylocation: string;
  phylocationid: number;
  ordered: Date;
  completed: Date;
  created: Date;
};
export type IOrderLineState = "created" | "started" | "deleted" | "sorted";
export type IAPIOrderLineModel = IWithId & {
  state: IOrderLineState;
  name: string;
  externalid: string;
  created: Date;
  updated: Date;
  sorted: Date;
  barcode: string;
  quantity: number;
  sortedqty: number;
  inductionqty: number;
  hospitalqty: number;
  remainingqty: number;
  bufferqty: number;
  inprocessqty: number;
  hazmat: boolean;
  fragile: boolean;
  weight: number;
  length: number;
  width: number;
  height: number;
  uom: string;
};

export type IAPICheckUnsortedOrders = {
  message: string;
  content: IAPICheckUnsortedOrdersContent[];
};

export type IAPICheckUnsortedOrdersContent = {
  waveID: number;
  waveName: string;
  unsortedOrders: number;
};

export type IAPIFilterRunWave = {
  message: string;
  content: IAPIFilterRunWaveContent[];
};

export type IAPIFilterRunWaveContent = IWithId & {
  name: string;
};

export type IAPIGetWaveSummary = {
  message: string;
  content: IAPIGetWaveSummaryContent[];
};

export type IAPIStepPhysLocation = {
  message: string;
  content: IAPIStepPhysLocationContent[];
};

export type IAPIStepPhysLocationContent = {
  physLocationInWave: number;
  readySortingOutputs: number;
};

export type IAPIcheckSortingOuputs = {
  message: string;
  content: IAPIcheckSortingOuputsContent[];
};

export type IAPIcheckSortingOuputsContent = {
  unassignedContainers: number;
};

export type IAPIReplaceContainers = {
  message: string;
  content: IAPIReplaceContainersContent[];
};

export type IAPIReplaceContainersContent = IWithId & {
  name: string;
  container: string;
  barcode: string;
  changecont: boolean;
  sorterID: number;
};

export type IAPIGetWaveSummaryContent = {
  avbSort: number;
  full: number;
  noContainer: number;
  noPhysLoc: number;
  sortPlanName: string;
  total: number;
  waveLocations: number;
  waveName: string;
  waveOrders: number;
  waveType: string;
};

export type IAPIUpdateSortPlanIDModel = {
  message: string;
};

export type IAPIPhysLocation = {
  message: string;
  content: IAPIPhysLocationContent[];
};

export type IAPIPhysLocationContent = {
  id: number;
  location: string;
};

export type IAPIUpdateSortPlanDetail = {
  message: string;
};

export type IAPISettingModulesModel = {
  message: string;
  content: IAPISettingModulesContent[];
};

export type IAPISettingModulesContent = {
  id: number;
  name: string;
  health: string;
};

export type IAPISettingRestartModulesModel = {
  message: string;
  content: IAPISettingRestartModulesContent[];
};

export type IAPISettingRestartModulesContent = {
  id: number;
  name: string;
  sorterID: number;
  sorterName: string;
};

export type IAPISettingComponentsModel = {
  message: string;
  content: IAPISettingComponentsContent[];
};

export type IAPISettingComponentsContent = {
  moduleID: number;
  name: string;
  sorterID: number;
  state: string;
  description: string;
  uuid: string;
};

export type IAPISettingComponentsTestModel = {
  message: string;
};

export type IAPISortingOutputsModel = {
  unassignedContainers: number;
};

export type IAPIWaveSummaryModel = {
  waveName: string;
  waveType: string;
  waveOrders: number;
  waveLocations: number;
  sortPlanName: string;
  total: number;
  avbSort: number;
  noPhysLoc: number;
  noContainer: number;
  full: number;
};

export type IAPIMessageModel<T> = {
  message: string;
  content: T[];
};

export type IAPIPhysicalLocationsModel = {
  physLocationInWave: number;
  readySortingOutputs: number;
};

export type IAPIChangeContainerModel = {
  id: number;
  sorterID: number;
  name: string;
  container: string;
  barcode: string;
  changecont: boolean;
};

export type IAPIRunWaveResponseContentModel = {
  resultOk: boolean;
  errno: number;
  message: string;
};

export type IAPIRunWaveResponseModel = {
  message: string;
  content: IAPIRunWaveResponseContentModel[];
};

export type IServerWaveCorrectionsModel = IWithId & {
  barcode: string;
  container?: string | null;
  created: Date;
  destinationName?: string | null;
  inducted: Date;
  inductionName: string;
  indupointName: string;
  itemID: number;
  itemQuantity: number;
  lineID: number;
  lineName: string;
  orderExternal: string;
  orderName: string;
  sorted: Date;
  state: string;
  totalQuantity: number;
  updated: Date;
  username: string;
  waveid: number;
};

export type IAPIwaveSorted = {
  message: string;
  content: boolean[];
};

export type IAPICameraImageModel = {
  message: string;
  content: IAPICameraImageContent[];
};

export type IAPICameraImageContent = {
  cameraId: number;
  imageName: string;
  imageData: string;
  barcodeScanned: boolean;
};

export type IAPIRobotsMapData = {
  message: string;
  content: IAPIRobotsMapDataContent[];
};

export type IAPIRobotsMapDataContent = IWithId & {
  map: string;
  width: number;
  height: number;
};

export type IAPIImagesData = {
  message: string;
  content: IAPIImagesDataContent[];
};

export type IAPIImagesDataContent = IWithId & {
  name: string;
  state: string;
  type: string;
  icon: string;
  width: number;
  height: number;
};

export type IWebsocketModelMapUpdateMessage = {
  message: IWebSocketMapUpdateModel[];
  messageType: WebsocketMessageType.mapUpdate;
};

export type IWebSocketMapUpdateModel = IWithId & {
  name: string;
  state: string;
  type: string;
  realPosition: number[];
  mapPosition: number[];
  visible: boolean;
  info: Record<string, any>;
  layer: number;
  mapId: number;
};
