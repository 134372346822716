import { observer } from "mobx-react";
import { useSorterWavesStore } from "../hooks/use-waves-store";
import { Box } from "@mui/material";
import DateTimePicker from "wes_shell_app/date-time-picker";
import { StateSelector } from "common/base-components/selectors/state-selector";
import { TypeSelector } from "common/base-components/selectors/type-selector";

export const WavesDataTableFilter = observer(() => {
  const store = useSorterWavesStore();

  return (
    <Box className="flex flex-row justify-between space-x-2 w-full mt-2 mb-8">
      <StateSelector
        value={store.getFilterValue("state")}
        onChange={(v) => store.setFilter("state", v)}
        onClear={() => store.clearFilter("state")}
      />
      <TypeSelector
        value={store.getFilterValue("type")}
        onChange={(v) => store.setFilter("type", v)}
        onClear={() => store.clearFilter("type")}
      />
      <DateTimePicker
        fullWidth
        intlId="createdFrom"
        value={store.getFilterValue("createdFrom")}
        onConfirm={(v) => store.setDateFilterValue("createdFrom", v)}
        defaultValue={store.defaultTimeFromValue}
      />
      <DateTimePicker
        fullWidth
        intlId="createdTo"
        value={store.getFilterValue("createdTo")}
        onConfirm={(v) => store.setDateFilterValue("createdTo", v)}
      />
    </Box>
  );
});
