import { observer } from "mobx-react";
import { Drawer } from "wes_shell_app/drawer";
import { useSortPlansStore } from "features/sort-plans/hooks/use-sort-plans-store";
import { useDetailSortPlansStore } from "../hooks/use-detail-sort-plan-store";
import { DetailSortPlanTable } from "./detail-sort-plan-table";
import DetailSortPlansDataTableFilter from "./detail-sort-plan-table-filter";
import Big from "wes_shell_app/big";
import { getTranslation } from "wes_shell_app/localization-utils";

const DetailSortPlanDrawer = observer(() => {
  const store = useSortPlansStore();
  const detailStore = useDetailSortPlansStore();

  return (
    <Drawer
      anchor="right"
      onClose={store.closeDrawer}
      open={store.isDrawerOpen}
      sx={{ width: "70%" }}
    >
      <div>
        <Big weight="bold" sx={{ marginBottom: "1rem" }}>{`${getTranslation(
          "sortPlan"
        )}: ${detailStore.selectedSortPlan?.name}`}</Big>
      </div>
      <DetailSortPlansDataTableFilter />
      <DetailSortPlanTable />
    </Drawer>
  );
});

export default DetailSortPlanDrawer;
