import { Box } from "@mui/material";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

export type CameraImageProps = {
  base64String?: string;
  error?: boolean;
};

const CameraImage = (props: CameraImageProps) => {
  const { base64String, error } = props;
  return base64String ? (
    <Box
      sx={{
        borderRadius: 2,
      }}
      className="camera-image h-full"
      style={{
        backgroundImage: `url(${base64String})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    ></Box>
  ) : (
    <Box
      sx={{
        borderRadius: 2,
        border: error && 2,
        borderColor: "#d32f2f",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="camera-image h-full"
    >
      <VideocamOffIcon
        color={error ? "error" : "disabled"}
        sx={{ fontSize: "100px" }}
      />
    </Box>
  );
};

export default CameraImage;
