import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import Button from "wes_shell_app/button";
import Select from "wes_shell_app/select";
import Text from "wes_shell_app/text";
import { useSorterWavesStore } from "../../hooks/use-waves-store";

type waveStartDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const SortPlanDialog = observer((props: waveStartDialogProps) => {
  const store = useSorterWavesStore();
  const availableSortPlans = store.runWaveStore.availableSortPlans;
  const selectedSortPlanId = store.runWaveStore.selectedSortPlanId;

  const onContinueClick = async () => {
    await store.runWaveStore.refreshPhysicalLocations();
    await store.runWaveStore.refreshSortingOutputs();
    await store.runWaveStore.refreshContainersToReplace();
    await store.runWaveStore.refreshWaveSummary();

    props.onConfirm();
  };

  return (
    <Dialog
      intlTitle="sortPlanSelect"
      open={props.isOpened}
      onClose={props.onClose}
      actions={[
        <Button
          disabled={!selectedSortPlanId}
          color="success"
          intlId="continue"
          variant="contained"
          onClick={onContinueClick}
        />,
      ]}
    >
      <Text intlId="lauchesSorterWaveProccesing" />
      <Select
        sx={{ marginTop: "1rem" }}
        fullWidth
        required
        intlId="sortPlanSelect"
        placeholder="sortPlanSelect"
        items={
          availableSortPlans &&
          availableSortPlans.map((item) => {
            return { id: item.id, contentIntl: item.name };
          })
        }
        value={selectedSortPlanId}
        onChange={(v: number) => {
          store.runWaveStore.setSelectedSortPlanId(v);
        }}
      />
    </Dialog>
  );
});
