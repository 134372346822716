import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IWithTranslation } from "wes_shell_app/types";
import LocalizedText from "wes_shell_app/localized-text";

export type IKPIChartsProps = IWithTranslation & {
  intlId?: string;
  chart?: React.ReactNode;
  value?: number[];
  description?: string[];
  className?: string;
};

const KpiCharts = ({
  intlId,
  chart,
  value = [],
  description = [],
  className = "",
}: IKPIChartsProps) => {
  return (
    <Box className={className}>
      <Typography component="p">
        <LocalizedText intlId={intlId} />
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Box>
          {value.length === 2 ? (
            <Typography component="p">
              <Typography component="span" variant="h6" pr={1}>
                {value[0] || "0"}
              </Typography>
              /
              <Typography component="span" pl={1}>
                {value[1] || "0"}
              </Typography>
            </Typography>
          ) : value.length < 2 ? (
            <Typography component="p" variant="h6">
              {value[0] || "0"}
            </Typography>
          ) : null}
          {description.length === 2 ? (
            <Typography
              component="p"
              variant="caption"
              mt={1}
              color="text.secondary"
            >
              {description[0]}/{description[1]}
            </Typography>
          ) : description.length === 1 ? (
            <Typography
              component="p"
              variant="caption"
              mt={1}
              color="text.secondary"
            >
              {description[0]}
            </Typography>
          ) : null}
        </Box>
        <Box>{chart}</Box>
      </Box>
    </Box>
  );
};

export default KpiCharts;
