import { useAuthentication } from "wes_shell_app/use-authentication";
import CountdownCircle from "common/base-components/count-down-circle/count-down-circle";
import Text from "wes_shell_app/text";

const DialogRestartAppMessage = () => {
    const authentication = useAuthentication();

    return (
        <div className="flex flex-col gap-10 items-center">
            <CountdownCircle duration={30} onComplete={() => authentication.signOut()} size={90}/>
            <Text intlId="modulsRestartlogoutMessage" sx={{ textAlign: "center" }} />
        </div>
    );
};

export default DialogRestartAppMessage;
