import { environment } from "environment";
import { makeObservable, observable } from "mobx";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export type ISorterOrderLinesFilterParams = "searchExternalId";

export class SorterOrderLinesDataTableProvider extends DataTableStore<
  any,
  ISorterOrderLinesFilterParams
> {
  constructor() {
    super();
    makeObservable(this, {
      currentOrderId: observable,
    });
  }

  readonly sharedContextStore = new SharedContextStore();
  get endpointUrl(): string {
    return `ui/getOrderLines/${this.currentOrderId}`;
  }

  protected serviceApiUrl = environment.sorterServiceApi;
  currentOrderId: number = 0;

  get defaultFilter() {
    return new Map<ISorterOrderLinesFilterParams, any>();
  }

  get filterDependencies(): Map<
    ISorterOrderLinesFilterParams,
    ISorterOrderLinesFilterParams[]
  > {
    return new Map<
      ISorterOrderLinesFilterParams,
      ISorterOrderLinesFilterParams[]
    >();
  }
}
