import { SimpleCard } from "wes_shell_app/simpleCard";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import Button from "wes_shell_app/button";
import { getTranslation } from "wes_shell_app/localization-utils";
import { useNavigate } from "react-router-dom";
import { basePath, SorterRoutePaths } from "routing/sorter-menu";
import { FullscreenUtil } from "wes_shell_app/fullscreen-utils";

type InductionStationViewType = {
  id: number;
  name: string;
};

const SelectInductionStation = (props: InductionStationViewType) => {
  const { id, name } = props;

  const navigate = useNavigate();

  const handleClick = (id: number) => {
    FullscreenUtil.enterFullscreen();
    navigate(`${basePath}${SorterRoutePaths.inductionStation}/${id}`);
  };

  return (
    <div className="p-1 w-11/12 md:w-2/4 lg:w-1/4">
      <SimpleCard>
        <div className="flex flex-col items-center gap-5">
          <ControlCameraIcon sx={{ fontSize: "40px" }} />
          <span>{name}</span>
          <Button
            variant="contained"
            label={getTranslation("choose")}
            onClick={() => handleClick(id)}
          />
        </div>
      </SimpleCard>
    </div>
  );
};

export default SelectInductionStation;
