import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import { IClientSorterPlanModel } from "models/client-models";
import { useState } from "react";
import { IWithModalDialog } from "wes_shell_app/model-dialog";
import { useSortPlansStore } from "../hooks/use-sort-plans-store";
import { SortPlansRequestDataProvider } from "../stores/data/sort-plans-request-data-provider";
import { SortPlansCloneDialogForm } from "./sort-plans-clone-dialog-form";
import { Button } from "wes_shell_app/button";
import { useFieldStore } from "wes_shell_app/use-field-store";
import { getTranslation } from "wes_shell_app/localization-utils";

type ISortPlansDialogFormProps = IWithModalDialog<IClientSorterPlanModel>;

export const SortPlansCloneModelDialogForm = observer(
  (props: ISortPlansDialogFormProps) => {
    const [isOverrideDialogVisible, setOverrideDialogVisibility] =
      useState(false);
    const store = useSortPlansStore();
    const fieldStore = useFieldStore();
    const provider = new SortPlansRequestDataProvider(store);

    if (!store.isAdmin) return null;

    const handleCloneSortPlan = async () => {
      for (const [key, value] of fieldStore.fieldRequiredData.entries()) {
        if (!value) {
          store.setShowRequiredMessage(true);
          return;
        }
      }

      const response = await provider.createCloneSortPlan(
        store.selectedCloneSortPlan,
        store.newSortPlansName
      );

      if (!response) return;

      store.setSortPlansName("");
      store.forceReload();
      store.setShowRequiredMessage(false);
      store.resetSortPlanTableRowSelected();
      store.setSortPlanCloneButton(false);
      setOverrideDialogVisibility(false);
      store.resetSelectedCloneSortPlan(true);
      fieldStore.resetFieldRequiredData();
    };

    const handleCloneSortPlanCancel = () => {
      store.setSortPlansName("");
      fieldStore.resetFieldRequiredData();
      setOverrideDialogVisibility(false);
      store.setShowRequiredMessage(false);
    };

    return (
      <>
        <Dialog
          open={isOverrideDialogVisible}
          onClose={handleCloneSortPlanCancel}
          cancelLabel="cancel"
          confirmAction={handleCloneSortPlan}
          confirmLabel="save"
          confirmActionVariant="confirm"
          intlTitle={"cloneSortPlan"}
          cannotClosed={true}
          disableEscapeKeyDown={true}
          message={
            store.showRequiredMessage
              ? getTranslation("requiredFieldDialog")
              : ""
          }
        >
          <SortPlansCloneDialogForm />
        </Dialog>
        <Button
          variant={"contained"}
          intlId={"clone"}
          onClick={() => setOverrideDialogVisibility(true)}
        />
      </>
    );
  }
);
