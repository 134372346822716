import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { useDetailSortPlansStore } from "../hooks/use-detail-sort-plan-store";
import { FilterSelector } from "common/common-components/selectors/filter-selector";
import { SelectItemType } from "wes_shell_app/types";

const DetailSortPlansDataTableFilter = observer(() => {
  const store = useDetailSortPlansStore();

  const StateItems: SelectItemType[] = [
    {
      contentIntl: "statusActive",
      id: "active",
    },
    {
      contentIntl: "statusBlocked",
      id: "blocked",
    },
  ];

  const LevelItems: SelectItemType[] = [
    {
      contentIntl: "level0",
      id: "0",
    },
    {
      contentIntl: "level1",
      id: "1",
    },
  ];

  const FingerItems: SelectItemType[] = [
    {
      contentIntl: "finger1",
      id: "Finger 1",
    },
    {
      contentIntl: "finger2",
      id: "Finger 2",
    },
  ];

  const SideItems: SelectItemType[] = [
    {
      contentIntl: "side_a",
      id: "A",
    },
    {
      contentIntl: "side_b",
      id: "B",
    },
    {
      contentIntl: "side_c",
      id: "C",
    },
  ];

  return (
    <Box className="flex flex-row justify-between space-x-2 w-full mt-2 mb-8 items-end">
      <div className="flex flex-row w-full gap-5">
        <div className="w-48">
          <FilterSelector
            items={StateItems}
            intld={"status"}
            placeholder={"status"}
            value={store.getFilterValue("state")}
            onChange={(v) => store.setFilter("state", v)}
            onClear={() => store.clearFilter("state")}
          />
        </div>
        <div className="w-48">
          <FilterSelector
            items={LevelItems}
            intld={"level"}
            placeholder={"level"}
            value={store.getFilterValue("level")}
            onChange={(v) => store.setFilter("level", v)}
            onClear={() => store.clearFilter("level")}
          />
        </div>
        <div className="w-48">
          <FilterSelector
            items={FingerItems}
            intld={"finger"}
            placeholder={"finger"}
            value={store.getFilterValue("finger")}
            onChange={(v) => store.setFilter("finger", v)}
            onClear={() => store.clearFilter("finger")}
          />
        </div>
        <div className="w-48">
          <FilterSelector
            items={SideItems}
            intld={"side"}
            placeholder={"side"}
            value={store.getFilterValue("side")}
            onChange={(v) => store.setFilter("side", v)}
            onClear={() => store.clearFilter("side")}
          />
        </div>
      </div>
    </Box>
  );
});

export default DetailSortPlansDataTableFilter;
