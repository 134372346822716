import { Dialog } from "common/base-components/dialog/dialog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Title from "wes_shell_app/text";
import { CheckCircleOutline } from "@mui/icons-material";

export type DashboardWaveControlResultProps = {
  open: boolean;
  onClose: () => void;
  intlText: string;
  success: boolean;
};

const DashboardWaveControlResultDialog = (
  props: DashboardWaveControlResultProps
) => {
  const { open, onClose, intlText, success } = props;

  return (
    <Dialog
      open={open}
      intlTitle="waveControl"
      cancelAction={onClose}
      onClose={onClose}
    >
      <div className="flex flex-col gap-10 items-center">
        {success ? (
          <CheckCircleOutline sx={{ fontSize: 130 }} color="success" />
        ) : (
          <HighlightOffIcon sx={{ fontSize: 130 }} color="error" />
        )}
        <Title intlId={intlText} sx={{ textAlign: "center" }} />
      </div>
    </Dialog>
  );
};

export default DashboardWaveControlResultDialog;
