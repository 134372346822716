import Select from "wes_shell_app/select";
import { isUndefined } from "lodash";
import { SelectItemType } from "wes_shell_app/types";

type IActiveFilterSelectorProps = {
  value: string;
  items: SelectItemType[];
  formSizeSmall?: boolean;
  onChange: (value: string) => void;
  intld: string;
  placeholder: string;
  readOnly?: boolean;
};

const DetailSortPlanTableRowSelector = (props: IActiveFilterSelectorProps) => {
  const {
    value,
    onChange,
    items,
    formSizeSmall,
    intld,
    placeholder,
    readOnly,
  } = props;

  const onChangeHandler = (v: string) => onChange(v);

  return (
    <Select
      fullWidth
      intlId={intld}
      placeholder={placeholder}
      items={items}
      value={!isUndefined(value) ? `${value}` : undefined}
      onChange={onChangeHandler}
      formSizeSmall={formSizeSmall}
      readOnly={readOnly}
    />
  );
};

export default DetailSortPlanTableRowSelector;
