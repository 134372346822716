import { observer } from "mobx-react";
import { IWithId } from "wes_shell_app/api-types";
import Button from "wes_shell_app/button";
import { useState } from "react";
import { useSorterWavesStore } from "../hooks/use-waves-store";
import { UnsortedOrdersDialog } from "./dialogs/unsorted-orders-dialog";
import { SortPlanDialog } from "./dialogs/sort-plan-dialog";
import { SortingOutputsDialog } from "./dialogs/sorting-outputs-dialog";
import { RunWaveSuccessDialog } from "./dialogs/run-wave-success-dialog";
import { RunWaveFailureDialog } from "./dialogs/run-wave-failure-dialog";
import { RunWaveConfirmDialog } from "./dialogs/run-wave-confirm-dialog";
import { PhysicalLocationsDialog } from "./dialogs/physical-locations-dialog";
import { ContainersToReplaceDialog } from "./dialogs/containers-to-replace-dialog";

type RunWaveDialogButtonProps<T extends IWithId> = {
  icon?: React.ReactElement;
  item?: T;
};

export const RunWaveDialogButton = observer(
  <T extends IWithId>(props: RunWaveDialogButtonProps<T>) => {
    const icon = props.icon;
    const item = props.item;
    const store = useSorterWavesStore();
    const [ordersDialog, setOrdersDialog] = useState<boolean>(false);
    const [sortPlanDialog, setSortPlanDialog] = useState<boolean>(false);
    const [locationsDialog, setLocationsDialog] = useState<boolean>(false);
    const [outputsDialog, setOutputsDialog] = useState<boolean>(false);
    const [containersDialog, setContainersDialog] = useState<boolean>(false);
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
    const [successDialog, setSuccessDialog] = useState<boolean>(false);
    const [failureDialog, setFailureDialog] = useState<boolean>(false);

    if (!store.isLoaded) return null;

    const resetPopups = () => {
      setOrdersDialog(false);
      setSortPlanDialog(false);
      setLocationsDialog(false);
      setOutputsDialog(false);
      setContainersDialog(false);
      setConfirmDialog(false);
      setSuccessDialog(false);
      setFailureDialog(false);
      store.runWaveStore.setSelectedSortPlanId(undefined);
      store.runWaveStore.setSelectedWaveId(undefined);
      store.runWaveStore.setAddUnsortedOrders(false);
      store.runWaveStore.setAutoPhysAssignment(false);
    };

    const onRunButtonClick = () => {
      store.runWaveStore.setSelectedWaveId(item.id);
      if (store.runWaveStore.unsortedOrders) setOrdersDialog(true);
      else setSortPlanDialog(true);
    };

    const confirmUnsortedOrders = () => {
      setOrdersDialog(false);
      setSortPlanDialog(true);
    };

    const confirmSortPlan = () => {
      setSortPlanDialog(false);

      if (store.runWaveStore.physicalLocations) setLocationsDialog(true);
      else if (
        store.runWaveStore.sortingOutputs &&
        store.runWaveStore.sortingOutputs.unassignedContainers > 0
      )
        setOutputsDialog(true);
      else if (
        store.runWaveStore.containersToReplace &&
        store.runWaveStore.containersToReplace.length > 0
      )
        setContainersDialog(true);
      else setConfirmDialog(true);
    };

    const confirmPhysicalLocations = () => {
      setLocationsDialog(false);
      if (
        store.runWaveStore.sortingOutputs &&
        store.runWaveStore.sortingOutputs.unassignedContainers > 0
      )
        setOutputsDialog(true);
      else if (
        store.runWaveStore.containersToReplace &&
        store.runWaveStore.containersToReplace.length > 0
      )
        setContainersDialog(true);
      else setConfirmDialog(true);
    };

    const confirmSortingOutputs = () => {
      setOutputsDialog(false);
      if (
        store.runWaveStore.containersToReplace &&
        store.runWaveStore.containersToReplace.length > 0
      )
        setContainersDialog(true);
      else setConfirmDialog(true);
    };

    const confirmContainersToReplace = () => {
      setContainersDialog(false);
      setConfirmDialog(true);
    };

    const onRunSuccess = () => {
      setConfirmDialog(false);
      setSuccessDialog(true);
    };

    const onRunFailure = () => {
      setConfirmDialog(false);
      setFailureDialog(true);
    };

    return (
      <>
        <UnsortedOrdersDialog
          isOpened={ordersDialog}
          onClose={resetPopups}
          onConfirm={confirmUnsortedOrders}
        />
        <SortPlanDialog
          isOpened={sortPlanDialog}
          onClose={resetPopups}
          onConfirm={confirmSortPlan}
        />
        <PhysicalLocationsDialog
          isOpened={locationsDialog}
          onClose={resetPopups}
          onConfirm={confirmPhysicalLocations}
        />
        <SortingOutputsDialog
          isOpened={outputsDialog}
          onClose={resetPopups}
          onConfirm={confirmSortingOutputs}
        />
        <ContainersToReplaceDialog
          isOpened={containersDialog}
          onClose={resetPopups}
          onConfirm={confirmContainersToReplace}
        />
        <RunWaveConfirmDialog
          isOpened={confirmDialog}
          onClose={resetPopups}
          onSuccess={onRunSuccess}
          onFailure={onRunFailure}
        />
        <RunWaveSuccessDialog isOpened={successDialog} onClose={resetPopups} />
        <RunWaveFailureDialog isOpened={failureDialog} onClose={resetPopups} />
        <Button variant="icon" icon={icon} onClick={onRunButtonClick} />
      </>
    );
  }
);

export default RunWaveDialogButton;
