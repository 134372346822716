import { Dialog } from "common/base-components/dialog/dialog";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Typography } from "@mui/material";
import { getTranslation } from "wes_shell_app/localization-utils";
import Title from "wes_shell_app/title";

export type SortPlansFinishWaveControlProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DashboardFinishWaveControlDialog = (
  props: SortPlansFinishWaveControlProps
) => {
  const { open, onClose, onConfirm } = props;

  return (
    <Dialog
      open={open}
      intlTitle={"waveControl"}
      cancelAction={onClose}
      onClose={onClose}
      confirmAction={onConfirm}
      confirmLabel="finish"
    >
      <div className="flex flex-col gap-10 items-center">
        <ErrorOutlineOutlinedIcon
          sx={{ fontSize: 130, color: (theme) => theme.palette.grey[300] }}
        />
        <Box>
          <Title intlId="finishSorter" sx={{ textAlign: "center" }} />
          <Typography
            component="div"
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: getTranslation("unsortedOrders"),
            }}
          />
        </Box>
      </div>
    </Dialog>
  );
};

export default DashboardFinishWaveControlDialog;
