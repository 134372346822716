import * as React from "react";
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Backdrop,
  Box,
  Typography,
} from "@mui/material";
import Big from "wes_shell_app/big";
import { makeAutoObservable } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import { IWithChildren } from "wes_shell_app/types";
import { ConfirmDialogButton } from "./confirm-dialog-button";
import { CloseDialogButton } from "./close-dialog-button";
import { ErrorOutline } from "@mui/icons-material";

type IDialogPropsBase = IWithChildren & {
  open?: boolean;
  intlTitle?: string;
};

type IDialogPropsActions = {
  actions: React.ReactNode[];
};

type IDialogActionProps = {
  confirmLabel?: string;
  confirmDisabled?: boolean;
  confirmAction?: () => void;
  confirmActionVariant?: "contained" | "confirm";
  cancelLabel?: string;
  cancelDisabled?: boolean;
  cancelAction?: () => void;
  disableEscapeKeyDown?: boolean;
  cannotClosed?: boolean;
  dontShowCancelButton?: boolean;
  DialogActionsSX?: React.CSSProperties;
  message?: string;
};

type IDialogProps = IDialogPropsBase &
  (IDialogActionProps | IDialogPropsActions) & {
    provider?: any;
    trigger?: React.ReactElement;
    onClose?: () => void;
  };

export const Dialog = observer((props: IDialogProps) => {
  const { actions } = props as IDialogPropsActions;
  const {
    cancelDisabled,
    cancelLabel,
    confirmAction,
    confirmDisabled,
    confirmLabel,
    confirmActionVariant,
    disableEscapeKeyDown,
    cannotClosed,
    dontShowCancelButton = false,
    DialogActionsSX,
    message,
  } = props as IDialogActionProps;

  const state = useLocalObservable(() => ({
    store: new DialogStore(),
  }));

  React.useEffect(() => {
    if (props.open) {
      state.store.openDialog();
    } else {
      state.store.closeDialog();
    }
  }, [props.open, state.store]);

  return (
    <>
      {props.trigger &&
        React.cloneElement(props.trigger, { onClick: state.store.openDialog })}
      <MuiDialog
        open={state.store.isOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          if (cannotClosed) return;
          state.store.closeDialog();
          props.onClose?.();
        }}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <DialogTitle>
          <Big intlId={props.intlTitle} />
        </DialogTitle>
        <Divider />
        {message && (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: "#d32f2f",
              padding: "1rem",
              marginTop: "1rem",
            }}
          >
            <ErrorOutline sx={{ color: "white" }} />
            <Typography
              variant="body1"
              sx={{ color: "white", paddingLeft: "1rem" }}
            >
              {message}
            </Typography>
          </Box>
        )}
        <DialogContent>{props.children}</DialogContent>
        <Divider />
        <DialogActions sx={DialogActionsSX}>
          {actions &&
            actions.map((action, index) => (
              <React.Fragment key={index}>{action}</React.Fragment>
            ))}
          {!dontShowCancelButton && (
            <CloseDialogButton
              label={cancelLabel || "Close"}
              onClick={() => {
                state.store.closeDialog();
                props.onClose?.();
              }}
              disabled={cancelDisabled}
            />
          )}
          {confirmAction && (
            <ConfirmDialogButton
              intlId={confirmLabel || "confirm"}
              onClick={confirmAction}
              disabled={confirmDisabled}
              variant={confirmActionVariant || "contained"}
            />
          )}
        </DialogActions>
      </MuiDialog>
    </>
  );
});

type TPrivates = "_processDialogAction" | "_openDialog";
type IAction = "cancel" | "confirm" | "open";

class DialogStore {
  constructor() {
    makeAutoObservable<this, TPrivates>(this);
  }

  isOpen: boolean = false;
  openDialog = () => (this.isOpen = true);
  closeDialog = () => (this.isOpen = false);
}
