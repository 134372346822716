import { environment } from "environment";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { IAPICheckWaveRunningContent, IAPICheckWaveRunningModel } from "models/server-models";
import alerts from "wes_shell_app/alerts";
import { dataGET } from "wes_shell_app/api-utils";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class WaveRunningStore {
    private readonly appContext = new SharedContextStore();

    constructor() {
        makeObservable(this, {
            currentUserStore: computed,
            isAdmin: computed,
            isLoaded: computed,
            waveRunning: observable,
            getWaveRunning: action,
        });
    }

    waveRunning: IAPICheckWaveRunningContent | null = null;

    async getWaveRunning() {
        try {
            const response: IAPICheckWaveRunningModel = await dataGET(`${environment.sorterServiceApi}ui/checkWaveRunning/${this.appContext.appContext.currentStationId}`);
            runInAction(() => {
                this.waveRunning = response.content[0];
            });
        } catch (e) {
            const fixedErrorMessage = (e.message as string).replace('Error: ', '');
            const errorModel = JSON.parse(fixedErrorMessage);
            alerts.error({ message: errorModel.message });
        }
    }

    get currentUserStore() { return this.appContext.appContext.accessRights; }
    get isAdmin() { return this.currentUserStore.isAdminRole || false; }
    get isLoaded() { return this.currentUserStore.isLoaded && this.waveRunning !== null; }
}
