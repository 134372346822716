import BaseView from "wes_shell_app/base-view";
import { SortPlansDataTable } from "../components/sort-plans-data-table";
import SortPlansDataTableFilter from "../components/sort-plans-data-table-filter";
import { SortPlansModelDialogForm } from "../components/sort-plans-model-dialog-form";
import { useEffect } from "react";
import { useSortPlansStore } from "../hooks/use-sort-plans-store";
import { SortPlansCloneModelDialogForm } from "../components/sort-plans-clone-model-dialog-form";
import DetailSortPlanDrawer from "../../sort-plan-detail/components/detail-sort-plan-drawer";
import SharedContextStore from "wes_shell_app/shared-context-store";

const SortPlansView = () => {
  const store = useSortPlansStore();
  const sharedContextStore = new SharedContextStore();
  const UserAccess =
    sharedContextStore.appContext.accessRights.getUserAccessEnums;

  useEffect(() => {
    store.setSortPlanCloneButton(false);
    store.resetSortPlanTableRowSelected();
  }, [store]);

  return (
    <BaseView accessKey={UserAccess.SORTER_NAV_ITEM}>
      <SortPlansDataTableFilter
        cloneActionContent={
          <SortPlansCloneModelDialogForm dialogTitleIntl="cloneSortPlan" />
        }
        actionContent={
          <SortPlansModelDialogForm dialogTitleIntl="newSortPlanCreation" />
        }
      />
      <SortPlansDataTable />
      <DetailSortPlanDrawer />
    </BaseView>
  );
};

export default SortPlansView;
