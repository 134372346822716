import { useParams } from "react-router-dom";
import InductionStationView from "features/induction-station/views/induction-station-view"; /* TODO MIJN prepsat na induction-station/.. */

const InductionStationViewWrapper: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const numericId = parseInt(id!, 10);
  if (isNaN(numericId)) {
    return;
  }
  return <InductionStationView id={numericId!} />;
};

export default InductionStationViewWrapper;
