import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Text from "wes_shell_app/text";

function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    maxValue: number;
    size?: number;
  }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={Math.round((100 / props.maxValue) * props.value)}
        size={props.size}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text intlId={`${Math.round(props.value)}`} />
      </Box>
    </Box>
  );
}

type CountdownCircleProps = {
  duration: number;
  onComplete: () => void;
  size?: number;
};

export default function CountdownCircle(props: CountdownCircleProps) {
  const [progress, setProgress] = React.useState(1);
  const { duration, onComplete, size } = props;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= duration) {
          onComplete();
          return prevProgress;
        }
        return prevProgress + 1;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [duration, onComplete]);

  return (
    <CircularProgressWithLabel
      value={progress}
      maxValue={duration}
      size={size}
    />
  );
}
