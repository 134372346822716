import { Adjust } from "@mui/icons-material";
import ResizableCard from "common/base-components/card/card-resizable";
import { observer } from "mobx-react";
import { IClientInduPiontsModel } from "models/client-models";
import { useInductionStationViewStore } from "../hooks/use-induction-station-view-store";
import IndupointDetail from "./indupoint-detail";

type IndupointType = {
  indupoint: IClientInduPiontsModel;
};

const Indupoint = observer((props: IndupointType) => {
  const store = useInductionStationViewStore();

  const { indupoint } = props;

  const handleIndupoint = (value: boolean) => {
    value
      ? store.setIndupointsState(
          indupoint.indupointId,
          indupoint.indupointName,
          "open"
        )
      : store.closeIndupoint(indupoint.indupointId, indupoint.indupointName);
  };

  return (
    <ResizableCard
      key={indupoint.indupointId}
      minWidth="7%"
      maxWidth="100%"
      icon={<Adjust />}
      simpleCardClassName="pt-0 h-96"
      checked={indupoint.opened}
      onChange={(value) => handleIndupoint(value)}
      size={indupoint.opened ? "100%" : "7%"}
      children={
        <IndupointDetail
          id={indupoint.indupointId}
          indupointName={indupoint.indupointName}
        />
      }
      className="relative"
    />
  );
});

export default Indupoint;
