import { Dialog } from "common/base-components/dialog/dialog";
import { IAPISettingComponentsContent } from "models/server-models";
import SettingsDialogServicesTest from "./content/settings-dialog-services-test";

interface SettingsServicesTestDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    component: IAPISettingComponentsContent | null;
}

const SettingsServicesTestDialog: React.FC<SettingsServicesTestDialogProps> = ({
    open,
    onClose,
    onConfirm,
    component,
}) => (
    <Dialog
        open={open}
        intlTitle="modulsRestart"
        cancelAction={onClose}
        confirmAction={onConfirm}
        confirmLabel="componentsStartTesting"
        onClose={onClose}
        cannotClosed={true}
        disableEscapeKeyDown={true}
    >
        <SettingsDialogServicesTest component={component} />
    </Dialog>
);

export default SettingsServicesTestDialog;