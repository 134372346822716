import { useState, useEffect } from 'react';

const useForbiddenEventCode = (): string[] => {
    const [forbiddenEventCode, setForbiddenEventCode] = useState<string[]>([]);

    useEffect(() => {
        const eventCode: string[] = [
            "Digit1", "Digit2", "Digit3", "Digit4", "Digit5", "Digit6", "Digit7", "Digit8", "Digit9", "Digit0", "Backquote", "Minus", "Equal"
        ];
        setForbiddenEventCode(eventCode);
    }, []);

    return forbiddenEventCode;
};

export default useForbiddenEventCode;
