import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { getTranslation } from "wes_shell_app/localization-utils";
import { useSorterWavesStore } from "../hooks/use-waves-store";
import { IClientWaveModel } from "models/client-models";
import Button from "wes_shell_app/button";
import { useNavigationStore } from "wes_shell_app/use-navigation-store";
import { useOrdersStore } from "features/wave-detail/hooks/use-orders-store";
import { SorterWavesViewStore } from "../stores/view/sorter-waves-view-store";
import { List, PlayArrow } from "@mui/icons-material";
import RunWaveDialogButton from "./run-wave-dialog-button";
import { useEffect } from "react";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
  },
  {
    label: "state",
  },
  {
    label: "name",
  },
  {
    label: "type",
  },
  {
    label: "started",
  },
  {
    label: "completed",
  },
  {
    label: "created",
  },
  {
    label: "",
  },
  {
    label: "orders",
  },
];

export const WavesDataTable = observer(() => {
  const store = useSorterWavesStore();

  useEffect(() => {
    store.runWaveStore.init();
  }, []);

  if (!store.isLoaded) return null;

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => (
        <RowTemplate key={item.id} item={item} store={store} />
      )}
    />
  );
});

type IRowTemplateProps = {
  item: IClientWaveModel;
  store: SorterWavesViewStore;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const navStore = useNavigationStore();
  const ordersStore = useOrdersStore();
  const { id, state, name, type, started, completed, created } = props.item;
  const detailLink = navStore.router.location.pathname + "/" + id;
  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{getTranslation(state + "State")}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{started}</TableCell>
      <TableCell>{completed}</TableCell>
      <TableCell>{created}</TableCell>
      <TableCell>
        {!props.store.runWaveStore.runningWave && state === "created" && (
          <RunWaveDialogButton
            icon={<PlayArrow color="success" />}
            item={props.item}
          />
        )}
      </TableCell>
      <TableCell>
        <Button
          variant="icon"
          icon={<List color="primary" />}
          onClick={() => {
            ordersStore.setCurrentWaveId(id);
            navStore.router.push(detailLink);
          }}
        />
      </TableCell>
    </TableRow>
  );
});
