import { observer } from "mobx-react";
import { SimpleCard } from "wes_shell_app/simpleCard";
import TimeChart from "wes_shell_app/time-chart";
import { useSorterChartsStore } from "../hooks/use-charts-store";

const SortedPiecesChart = observer(() => {
  const store = useSorterChartsStore().sortedPieces;

  return (
    <SimpleCard>
      <TimeChart
        data={store.items}
        store={store}
        intlId="sortedPieces"
        type="line"
      />
    </SimpleCard>
  );
});

export default SortedPiecesChart;
