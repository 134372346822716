import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import { useInductionStationViewStore } from "../hooks/use-induction-station-view-store";
import SelectInductionStation from "../components/select-induction-station";

const InductionStationSelectView = observer(() => {
  const store = useInductionStationViewStore();

  return (
    <BaseView cardBackgroundColor={false}>
      <div className="flex flex-wrap ">
        {store.actualInductionStations
          .flatMap((station) => station.inductionStations)
          .map((station) => (
            <SelectInductionStation
              key={station.inductionID}
              id={station.inductionID}
              name={station.inductionName}
            />
          ))}
      </div>
    </BaseView>
  );
});

export default InductionStationSelectView;
