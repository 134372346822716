import { Dialog } from "common/base-components/dialog/dialog";
import SettingsDialogServicesResult from "./content/settings-dialog-services-result";
import { IAPISettingComponentsContent } from "models/server-models";
import Button from "wes_shell_app/button";

type SettingsServicesResultDialogProps = {
  open: boolean;
  onClick: (endStatus: string) => void;
  onClose: () => void;
  component: IAPISettingComponentsContent | null;
};

const SettingsServicesResultDialog: React.FC<
  SettingsServicesResultDialogProps
> = ({ open, onClick, onClose, component }) => (
  <Dialog
    open={open}
    intlTitle="modulsRestartlogoutTitle"
    onClose={onClose}
    cannotClosed={true}
    disableEscapeKeyDown={true}
    dontShowCancelButton={true}
    actions={[
      <Button
        intlId={"resultOK"}
        onClick={() => onClick("ok")}
        key={1}
        sx={{
          backgroundColor: "#2e7d32",
          color: "white",
          "&:hover": { backgroundColor: "#1b5e20" },
        }}
      />,
      <Button
        intlId={"resultFailed"}
        onClick={() => onClick("error")}
        key={2}
        sx={{
          backgroundColor: "#d32f2f",
          color: "white",
          "&:hover": { backgroundColor: "#c62828" },
        }}
      />,
      <Button intlId={"resultSkip"} onClick={() => onClick("skip")} key={3} />,
    ]}
    DialogActionsSX={{ justifyContent: "center" }}
  >
    <SettingsDialogServicesResult component={component} />
  </Dialog>
);

export default SettingsServicesResultDialog;
