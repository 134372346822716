import { environment } from "environment";
import TimeChartDataProvider from "wes_shell_app/time-chart-data-provider";

class SorterChartsCommonDataProvider extends TimeChartDataProvider {
  constructor(name: string) {
    super(name);
  }

  protected get serviceApiUrl() {
    return environment.sorterServiceApi;
  }
  protected get endpointUrl() {
    return `ui/graph/${this.stationId}`;
  }
}

export class SorterChartsDataProvider {
  readonly sortedPieces = new SorterChartsCommonDataProvider(
    "Total Items Sorted"
  );
  readonly sortedOrders = new SorterChartsCommonDataProvider(
    "Total Orders Completed"
  );
}
