import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import { IClientWaveCorrectionModel } from "models/client-models";
import { WaveCorrectionsViewStore } from "../stores/wave-corrections-view-store";
import { useWaveCorrectionStore } from "../hooks/use-wave-corrections-view-store";
import WaveCorrectionsTableRowSelector from "./wave-corrections-table-row-selector";
import alerts from "wes_shell_app/alerts";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
    sortKey: "id",
  },
  {
    label: "lineID",
    sortKey: "lineID",
  },
  {
    label: "barcode",
    sortKey: "barcode",
  },
  {
    label: "name",
    sortKey: "lineName",
  },
  {
    label: "previousQuantity",
    sortKey: "totalQuantity",
  },
  {
    label: "actualQuantity",
    sortKey: "itemQuantity",
  },
  {
    label: "status",
    sortKey: "state",
  },
  {
    label: "waveID",
    sortKey: "waveid",
  },
  {
    label: "orderName",
    sortKey: "orderName",
  },
  {
    label: "inductionName",
    sortKey: "inductionName",
  },
  {
    label: "inductionPoint",
    sortKey: "indupointName",
  },
  {
    label: "destiantion",
    sortKey: "destinationName",
  },
  {
    label: "container",
    sortKey: "container",
  },
  {
    label: "user",
    sortKey: "username",
  },
  {
    label: "Inducted",
    sortKey: "inducted",
  },
  {
    label: "Sorted",
    sortKey: "sorted",
  },
  {
    label: "created",
    sortKey: "created",
  },
  {
    label: "updated",
    sortKey: "updated",
  },
];

const WaveCorrectionDataTable = observer(() => {
  const store = useWaveCorrectionStore();

  if (!store.isLoaded) return null;

  const handleChangeWaveItem = async (
    id: number,
    lineID: number,
    quantity: string
  ) => {
    try {
      const response = await store.updateWaveItemAPI(id, lineID, quantity);
      response && store.forceReload();
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  };

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => (
        <RowTemplate
          key={item.id}
          item={item}
          store={store}
          isAdmin={store.isAdmin}
          changeWaveItem={handleChangeWaveItem}
        />
      )}
    />
  );
});

type IRowTemplateProps = {
  item: IClientWaveCorrectionModel;
  store: WaveCorrectionsViewStore;
  isAdmin?: boolean;
  changeWaveItem: (id: number, lineID: number, quantity: string) => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const {
    id,
    lineID,
    barcode,
    lineName,
    totalQuantity,
    itemQuantity,
    state,
    waveid,
    orderName,
    inductionName,
    indupointName,
    destinationName,
    container,
    username,
    inducted,
    sorted,
    created,
    updated,
  } = props.item;

  const { changeWaveItem } = props;

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{lineID}</TableCell>
      <TableCell>{barcode}</TableCell>
      <TableCell>{lineName}</TableCell>
      <TableCell>{totalQuantity}</TableCell>
      <TableCell>
        <WaveCorrectionsTableRowSelector
          value={itemQuantity}
          item={props.item}
          onChange={(value) => changeWaveItem(id, lineID, value)}
          formSizeSmall={true}
        />
      </TableCell>
      <TableCell>{state}</TableCell>
      <TableCell>{waveid}</TableCell>
      <TableCell>{orderName}</TableCell>
      <TableCell>{inductionName}</TableCell>
      <TableCell>{indupointName}</TableCell>
      <TableCell>{destinationName}</TableCell>
      <TableCell>{container}</TableCell>
      <TableCell>{username}</TableCell>
      <TableCell>{inducted}</TableCell>
      <TableCell>{sorted}</TableCell>
      <TableCell>{created}</TableCell>
      <TableCell>{updated}</TableCell>
    </TableRow>
  );
});

export default WaveCorrectionDataTable;
