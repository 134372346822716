import { environment } from "environment";
import { IAPIWaveModel } from "models/server-models";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export type ISorterWavesFilterParams =
  | "state"
  | "type"
  | "createdTo"
  | "createdFrom";

export class SorterWavesDataTableProvider extends DataTableStore<
  IAPIWaveModel,
  ISorterWavesFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();
  get endpointUrl(): string {
    return `ui/getWaves/${this.sorterId}`;
  }

  protected serviceApiUrl = environment.sorterServiceApi;
  get sorterId() {
    return this.sharedContextStore.appContext.currentStationId;
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setDate(timeFrom.getDate() - 1);
    return timeFrom;
  }

  get defaultFilter() {
    return new Map<ISorterWavesFilterParams, any>([["state", "created"]]);
  }

  get filterDependencies(): Map<
    ISorterWavesFilterParams,
    ISorterWavesFilterParams[]
  > {
    return new Map<ISorterWavesFilterParams, ISorterWavesFilterParams[]>();
  }
}
