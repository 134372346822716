import React, { useState, useEffect } from "react";
import { SimpleCard } from "wes_shell_app/simpleCard";
import { Switch } from "wes_shell_app/switch";

type ResizableCardProps = {
  onChange?: (value: boolean) => void;
  intlId?: string;
  minWidth: string;
  maxWidth: string;
  checked?: boolean;
  size?: string;
  children?: React.ReactNode;
  simpleCardClassName?: string;
  icon?: React.ReactNode;
  className?: string;
};

const ResizableCard = (props: ResizableCardProps) => {
  const {
    onChange,
    intlId,
    minWidth,
    maxWidth,
    children,
    checked,
    simpleCardClassName,
    icon,
    size,
    className,
  } = props;
  const [cameraBoxSize, setCameraBoxSize] = useState<string>(size);
  const [transition, setTransition] = useState<boolean>(false);

  useEffect(() => {
    setCameraBoxSize(size);
  }, [size]);

  useEffect(() => {
    if (transition) {
      const timer = setTimeout(() => setTransition(false), 400);
      return () => clearTimeout(timer);
    }
  }, [transition]);

  const handleSwitchChange = (value: boolean) => {
    setTransition(true);
    setCameraBoxSize(value ? maxWidth : minWidth);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div
      className={`mt-2 ${className}`}
      style={{
        width: cameraBoxSize,
        transition: transition ? "width 0.4s ease" : "none",
        minWidth: "100px",
      }}
    >
      <SimpleCard className={`h-full ${simpleCardClassName}`} topMargin={false}>
        <div className="flex h-full">
          <div className="absolute">
            {icon}
            <Switch
              intlId={intlId}
              value={"End"}
              onChange={handleSwitchChange}
              checked={checked}
            />
          </div>
          <div className="mt-10 h-5/6 w-full">{checked && children}</div>
        </div>
      </SimpleCard>
    </div>
  );
};

export default ResizableCard;
