import { IClientWaveModel } from "models/client-models";
import { IAPIWaveModel } from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  SorterWavesDataTableProvider,
  ISorterWavesFilterParams,
} from "../data/sorter-waves-data-table-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { SorterRunWaveStore } from "../data/sorter-run-wave-store";
import { serverToClientWaveModel } from "models/mappers";

export class SorterWavesViewStore extends BaseTableViewStore<
  IAPIWaveModel,
  IClientWaveModel,
  ISorterWavesFilterParams,
  SorterWavesDataTableProvider
> {
  constructor() {
    super(new SorterWavesDataTableProvider());
  }

  private readonly appContext = new SharedContextStore();
  readonly runWaveStore = new SorterRunWaveStore();

  mapServerToClientModel = (item): IClientWaveModel =>
    serverToClientWaveModel(item);

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
}
