import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import Title from "wes_shell_app/big";
import { DashboardHeader } from "../components/dashboard-header";
import { SimpleCard } from "wes_shell_app/simpleCard";
import FatalDataTable from "../components/fatal-data-table";
import ProcessDataTable from "../components/process-data-table";
import TechnologyDataTable from "../components/technology-data-table";
import InfoWave from "../components/info_wave";
import HealthCheck from "../components/health-check";
import WaveControl from "../components/wave-control";
import SharedContextStore from "wes_shell_app/shared-context-store";

const DashboardView = observer(() => {
  const store = new SharedContextStore();
  const UserAccess = store.appContext.accessRights.getUserAccessEnums;

  return (
    <BaseView
      cardBackgroundColor={false}
      accessKey={UserAccess.SORTER_NAV_ITEM}
    >
      <DashboardHeader />
      <FatalDataTable />
      <div className="flex">
        <div className="w-full max-w-[80%]">
          <SimpleCard>
            <Title intlId="processTableMessagesLabel" />
            <ProcessDataTable />
          </SimpleCard>
          <SimpleCard>
            <Title intlId="technologyTableLabel" />
            <TechnologyDataTable />
          </SimpleCard>
        </div>
        <div className="pl-2 w-full max-w-[20%]">
          <SimpleCard>
            <Title intlId="waveInfo" />
            <InfoWave />
          </SimpleCard>
          <SimpleCard>
            <Title intlId="waveControl" />
            <WaveControl />
          </SimpleCard>
          <SimpleCard>
            <Title intlId="sorterHealthCheck" />
            <HealthCheck />
          </SimpleCard>
        </div>
      </div>
    </BaseView>
  );
});

export default DashboardView;
