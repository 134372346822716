import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { getTranslation } from "wes_shell_app/localization-utils";
import { useOrdersStore } from "../hooks/use-orders-store";
import { useOrderLinesStore } from "../hooks/use-order-lines-store";
import { IClientOrderModel } from "models/client-models";
import Button from "wes_shell_app/button";
import { Delete, List } from "@mui/icons-material";
import { SorterOrdersViewStore } from "../stores/view/sorter-orders-view-store";
import ConfirmDialog from "wes_shell_app/confirm-dialog";
import useConfirmDialog from "wes_shell_app/use-confirm-dialog";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
  },
  {
    label: "state",
  },
  {
    label: "name",
  },
  {
    label: "externalid",
  },
  {
    label: "phylocation",
  },
  {
    label: "phylocationid",
  },
  {
    label: "ordered",
  },
  {
    label: "completed",
  },
  {
    label: "created",
  },
  {
    label: "lines",
  },
  {
    label: "",
  },
];

export const OrdersDataTable = observer(() => {
  const store = useOrdersStore();
  const confirmDialog = useConfirmDialog();
  if (!store.isLoaded) return null;

  return (
    <>
      <ConfirmDialog
        onConfirm={() => store.deleteOrder(confirmDialog.item.id)}
      />
      <DataTable
        page={store.page}
        isLoading={!store.isLoaded}
        onPageChange={store.setPage}
        activeSortProperty={store.sortBy}
        onSortChange={store.setSort}
        onDirectionChange={store.setDirection}
        direction={store.direction}
        pageCount={store.totalCount}
        rows={store.items}
        pageSize={store.pageSize}
        onPageSizeChange={store.setPageSize}
        columns={columns}
        rowTemplate={(item) => (
          <RowTemplate
            key={item.id}
            item={item}
            store={store}
            dialog={confirmDialog}
          />
        )}
      />
    </>
  );
});

type IRowTemplateProps = {
  item: IClientOrderModel;
  store: SorterOrdersViewStore;
  dialog: { open: (item) => void };
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const drawerStore = useOrderLinesStore();
  const {
    id,
    state,
    name,
    externalid,
    phylocation,
    phylocationid,
    ordered,
    completed,
    created,
  } = props.item;
  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{getTranslation(state + "State")}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{externalid}</TableCell>
      <TableCell>{phylocation}</TableCell>
      <TableCell>{phylocationid}</TableCell>
      <TableCell>{ordered}</TableCell>
      <TableCell>{completed}</TableCell>
      <TableCell>{created}</TableCell>
      <TableCell>
        <Button
          variant="icon"
          icon={<List color="primary" />}
          onClick={() => {
            drawerStore.setCurrentOrderId(id);
            drawerStore.showDrawer();
          }}
        />
      </TableCell>
      <TableCell>
        {props.store.isAdmin && props.item.state === "created" && (
          <Button
            variant="icon"
            icon={<Delete color="error" />}
            onClick={() => props.dialog.open(props.item)}
          />
        )}
      </TableCell>
    </TableRow>
  );
});
