import { useWaveCorrectionStore } from "../hooks/use-wave-corrections-view-store";
import { Box } from "@mui/material";
import TextInputField from "common/base-components/input-field/text-input-field";

const WaveCorrectionsDataTableFilter = () => {
  const store = useWaveCorrectionStore();

  return (
    <Box className="flex flex-row justify-between space-x-2 w-full mt-2 mb-8 items-end">
      <div className="flex flex-row w-48">
        <TextInputField
          label="barcode"
          value={store.getFilterValue("barcode")}
          onChange={(v) => store.setFilter("barcode", v)}
        />
      </div>
    </Box>
  );
};

export default WaveCorrectionsDataTableFilter;
