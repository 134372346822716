import { Dialog } from "common/base-components/dialog/dialog";
import SettingsDialogServicesInfo from "./content/settings-dialog-services-info";
import { IAPISettingComponentsContent } from "models/server-models";

type SettingsServicesResultDialogProps = {
    open: boolean;
    onClose: () => void;
    component: IAPISettingComponentsContent | null;
};

const SettingsServicesInfoDialog: React.FC<SettingsServicesResultDialogProps> = ({ open, onClose, component }) => (
  <Dialog
    open={open}
    intlTitle="componentsInfromation"
    cancelAction={onClose}
    onClose={onClose}
    cannotClosed={true}
    disableEscapeKeyDown={true}
  >
    <SettingsDialogServicesInfo component={component} />
  </Dialog>
);

export default SettingsServicesInfoDialog;