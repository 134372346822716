import { Dialog } from "common/base-components/dialog/dialog";
import { observer } from "mobx-react";
import Button from "wes_shell_app/button";
import { useSorterWavesStore } from "../../hooks/use-waves-store";
import { Checkbox, Stack } from "@mui/material";
import Text from "wes_shell_app/text";
import { getTranslation } from "wes_shell_app/localization-utils";
import Small from "wes_shell_app/small";

type waveStartDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onFailure: () => void;
};

export const RunWaveConfirmDialog = observer((props: waveStartDialogProps) => {
  const store = useSorterWavesStore();

  if (!store.isLoaded) return;

  const onConfirm = async () => {
    try {
      await store.runWaveStore.runWave();
      props.onSuccess();
    } catch {
      props.onFailure();
    }
  };

  return (
    <Dialog
      intlTitle="runWaveConfirmTitle"
      open={props.isOpened}
      onClose={props.onClose}
      actions={[
        <Button
          color="success"
          intlId="start"
          variant="contained"
          onClick={onConfirm}
        />,
      ]}
    >
      {store.runWaveStore.waveSummary && (
        <Stack>
          <RowTemplate
            intlId="waveToRun"
            value={store.runWaveStore.waveSummary.waveName}
          />
          <RowTemplate
            intlId="type"
            value={store.runWaveStore.waveSummary.waveType}
          />
          <RowTemplate
            intlId="ordersInWave"
            value={store.runWaveStore.waveSummary.waveOrders}
          />
          <RowTemplate
            intlId="locationsInWave"
            value={store.runWaveStore.waveSummary.waveLocations}
          />
          <RowTemplate
            intlId="selectedSortPlan"
            value={store.runWaveStore.waveSummary.sortPlanName}
          />
          <RowTemplate
            intlId=""
            value={store.runWaveStore.waveSummary.total}
            description="total"
          />
          <RowTemplate
            intlId=""
            value={store.runWaveStore.waveSummary.avbSort}
            description="toSort"
          />
          <RowTemplate
            intlId="sortingOutputs"
            value={store.runWaveStore.waveSummary.noPhysLoc}
            description="noPhysLocationOutputs"
          />
          <RowTemplate
            intlId=""
            value={store.runWaveStore.waveSummary.noContainer}
            description="noContainerOutputs"
          />
          <RowTemplate
            intlId=""
            value={store.runWaveStore.waveSummary.full}
            description="fullOutputs"
          />

          <Stack
            direction="row"
            justifyContent="space-between"
            paddingLeft="10%"
            paddingRight="10%"
          >
            <Text intlId="autoAssignPhysLoc" />
            <Checkbox
              checked={store.runWaveStore.autoPhysAssignment}
              onChange={() =>
                store.runWaveStore.setAutoPhysAssignment(
                  !store.runWaveStore.autoPhysAssignment
                )
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
});

type RowTemplateProps = {
  intlId: string;
  value: number | string;
  description?: string;
};
const RowTemplate = observer((props: RowTemplateProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      paddingLeft="10%"
      paddingRight="10%"
    >
      <Text intlId={props.intlId || ""} />
      <Small>
        {!props.description
          ? props.value
          : `${props.value} (${getTranslation(props.description)})`}
      </Small>
    </Stack>
  );
});
