import React from "react";
import { debounce } from "lodash";
import { IInputFieldProps, InputField } from "./input-field";

export type TextInputFieldProps = IInputFieldProps<string>;

export const TextInputField = (props: TextInputFieldProps) => {
  const {
    label,
    readOnly,
    value,
    placeholder,
    onChange,
    onConfirm,
    type,
    useDebounce,
  } = props;
  const [internalValue, setInternalValue] = React.useState<string>(value);
  const debouncedChangeHandler = React.useRef(debounce(props.onChange, 200));

  function onValueChange(v, event) {
    setInternalValue(v);
    if (useDebounce) {
      debouncedChangeHandler.current(v);
    } else {
      onChange(v, event);
    }
  }

  if (value && internalValue !== value) setInternalValue(value);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) =>
    onConfirm?.(event.target.value);

  return (
    <InputField
      size={props.size}
      placeholder={placeholder}
      label={label}
      readOnly={readOnly}
      value={internalValue}
      onChange={onValueChange}
      onConfirm={onBlur}
      type={type}
      startAdornment={props.startAdornment}
      endAdormnent={props.endAdormnent}
    />
  );
};

export const MemoizedTextInputField = React.memo(TextInputField, isEqual);

function isEqual(
  prevProps: IInputFieldProps<string>,
  nextProps: IInputFieldProps<string>
) {
  if (prevProps.value !== nextProps.value) return false;
  return true;
}

export default TextInputField;
