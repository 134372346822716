import { usePlatformStore } from "../hooks/use-platform-store";
import DiagramMapView from "wes_shell_app/diagram-map-view";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";

const PlatformMap: React.FC = observer(() => {
  const store = usePlatformStore();
  const [showComponent, setShowComponent] = useState(false);
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    setRenderKey((prev) => prev + 1);
  }, [store.zoomLevel]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  if (!store.isLoaded) {
    return null;
  }

  return (
    <div className="min-h-full z-10">
      {showComponent && (
        <DiagramMapView
          key={`map-${renderKey}`}
          components={store.activeComponents}
          background={store.map?.content[0]}
          icons={store.images?.content}
          zoomLevel={store.zoomLevel}
        />
      )}
    </div>
  );
});

export default PlatformMap;
