import { IAPISettingComponentsContent } from "models/server-models";
import Text from "wes_shell_app/text";
import Small from "wes_shell_app/small";

type ISettingsDialogServicesResult = {
  component: IAPISettingComponentsContent | null;
};

const SettingsDialogServicesResult = (props: ISettingsDialogServicesResult) => {
  const { description } = props.component;

  return (
    <div>
      <Text intlId="componentsInfromation"/>
      <Small intlId={description || "componentsNothingInformation"} />
    </div>
  );
};

export default SettingsDialogServicesResult;
