import { observer } from "mobx-react";
import { useInductionStationViewStore } from "../hooks/use-induction-station-view-store";
import Indupoint from "./indupoint";
import Big from "wes_shell_app/big";

const Indupoints = observer(() => {
  const store = useInductionStationViewStore();

  return (
    <>
      <Big intlId="induPoints" className="mt-2" />
      <div className={`flex gap-2 h-[46em]`}>
        {store.actualIndupoints?.map((indupoint) => (
          <Indupoint indupoint={indupoint} key={indupoint.indupointId} />
        ))}
      </div>
    </>
  );
});

export default Indupoints;
