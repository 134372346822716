import { Dialog } from "common/base-components/dialog/dialog";
import { SortPlansViewStore } from "features/sort-plans/stores/sort-plans-view-store";
import Text from "wes_shell_app/text";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

type SortPlansPhysLocationDialogProps = {
  open: boolean;
  onClose: () => void;
  store: SortPlansViewStore;
  handlePhysLocationDialog: () => void;
};

const sortPlansPhysLocationDialog = (
  props: SortPlansPhysLocationDialogProps
) => {
  const { open, onClose, store, handlePhysLocationDialog } = props;
  return (
    <Dialog
      open={open}
      intlTitle="locationCheck"
      cancelAction={onClose}
      onClose={onClose}
      confirmAction={() => handlePhysLocationDialog()}
      confirmLabel="yes"
      cancelLabel="no"
    >
      <div className="flex flex-col gap-2 items-center">
        <ErrorOutlineOutlinedIcon
          sx={{ fontSize: 130, color: (theme) => theme.palette.grey[300] }}
        />
        <Text
          intlId="locationCheckContent"
          intlParams={{
            numberOfPhysLocation: store.physLocation[0]?.physLocationInWave,
            numberOfSortingOutputs: store.physLocation[0]?.readySortingOutputs,
          }}
        />
      </div>
    </Dialog>
  );
};

export default sortPlansPhysLocationDialog;
